import {type Editor} from 'slate';
import {type SyntheticEvent, useMemo, useRef} from 'react';

import {type EditableProps} from '../interface';
import {type SlateEditorHandlers, type SlateEditorProps} from '../components/SlateEditor';
import {SlateEditor} from '../plugins';
import {isEventHandled} from '../utils/event';

type SlateEditableEvents<T extends keyof SlateEditorHandlers = keyof SlateEditorHandlers> = {
  [key in T]?: EditableProps[T];
};

export const useEditableProps = (editor: Editor, props: SlateEditorProps = {}): EditableProps => {
  const {
    autoFocus,
    readOnly = false,
    className = 'slate-editable',
    style,
    onChange,
    value,
    initialValue,
    plugins,
    toolbar,
    getWidgetProps,
    skipSelectionChange,
    normalizeOnMount,
    ...rest
  } = props;
  const editableProps = editor.editableProps || rest;

  const ref = useRef(rest);
  ref.current = rest;

  const editableHandlers = useMemo(() => {
    const props = ref.current;
    if (!props) return {};
    return Object.entries(props)
      .filter(([k]) => k.startsWith('on'))
      .reduce<SlateEditableEvents>((handlers, [prop, handler]) => {
        handlers[prop] = (e: SyntheticEvent) => {
          const fn = SlateEditor.editableProp(editor, prop as keyof EditableProps);
          const res = handler(e, editor);
          if (isEventHandled(e)) return res;
          return fn?.(e);
        };
        return handlers;
      }, {});
  }, [editor]);

  return {
    autoFocus: !!autoFocus,
    readOnly,
    className,

    ...rest,
    ...editableProps,
    ...editableHandlers,
    style: {...editableProps.style, ...style}
  } as EditableProps;
};
