import {Editor, Transforms} from 'slate';

import {type NormalizeEntryFn} from '../core';
import {type ListElement} from '../../interface';
import {ListEditor} from './ListEditor';

export const normalizeList: NormalizeEntryFn<ListElement> = (editor, [list, path]) => {
  // List should not be empty
  if (!list.children.length) {
    // TODO: strange but necessary, review if we can remove that rule
    Transforms.insertNodes(editor, {text: ''}, {at: [...path, 0]});
    return true;
  }
  // List should not be empty or contain text/inlines
  if (Editor.hasInlines(editor, list)) {
    Transforms.removeNodes(editor, {at: path});
    return true;
  }

  // List should not contain non-listItems
  const hasNotListItems = list.children.some(n => !ListEditor.isListItem(n));
  if (hasNotListItems) {
    list.children.forEach((e, i) => {
      if (!ListEditor.isListItem(e)) {
        Transforms.wrapNodes(editor, {type: 'listItem', children: []}, {at: [...path, i]});
      }
    });
    return true;
  }

  // List type should be defined
  if (!ListEditor.isDefined(editor, list.list, list.listStyleType)) {
    const at = [...path, 0];
    if (Editor.hasPath(editor, at)) {
      Transforms.liftNodes(editor, {at});
      return true;
    }
  }
  return;
};
