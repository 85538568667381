import {Editor, type Element, Node, Transforms} from 'slate';

import {type BlockType} from 'components/SlateJS/definitions';

import {type NormalizeEntryFn} from './interface';
import {type SlateEditor} from '../SlateEditor';

export const normalizeBlock: NormalizeEntryFn<Element> = (editor: Editor & SlateEditor, entry) => {
  const [node, path] = entry;

  if (!node.type || node.type === 'paragraph') {
    // default blocks (or paragraphs in 0.47 slate for compatibility) should not be nested
    if (node.children.length && Editor.hasBlocks(editor, node)) {
      Transforms.unwrapNodes(editor, {at: path});
      return true;
    }
  }
  const allowedBlockTypes = editor.defRegistry?.getBlockTypes();

  if (!!node.type && !allowedBlockTypes?.has(node.type as BlockType)) {
    const props = Node.extractProps(node);

    const [parent] = Editor.parent(editor, path);
    if (Editor.isEditor(parent)) {
      Transforms.unsetNodes(editor, Object.keys(props), {at: path});
    } else {
      Transforms.unwrapNodes(editor, {at: path});
      return true;
    }
  }
  return;
};
