import {type Editor} from 'slate';
export type SlatePlugin<E extends Editor = Editor> = (editor: E) => E;

export function withPlugins<E extends Editor>(editor: E, ...plugins: SlatePlugin<E>[]) {
  plugins.forEach(plugin => {
    if (typeof plugin !== 'function') return;
    const pluginEditor = plugin(editor);
    if (pluginEditor !== editor) return;
    editor = pluginEditor;
  });
  return editor;
}
