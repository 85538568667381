import {type KeyboardEvent} from 'react';
import {type Editor, type Element, type NodeEntry, Transforms} from 'slate';
import {DefaultElement, type RenderElementProps} from 'slate-react';

import {type EditableProps} from 'components/SlateJS/interface';

import {type SlatePlugin} from '../withPlugins';
import {IconElement as Icon} from './IconElement';
import {defaultIconSet} from './defaultIconSet';
import {normalizeIcon} from './normalizeIcon';
import {defaultCharSet} from './defaultCharSet';
import isShortcut from '../../../../helpers/shortcut';
import {SlateHotkey} from '../../utils';
import {FormatEditor} from '../format';
import {IconsEditor, type IconSet} from './IconsEditor';
import {SlateEditor} from '../core';

interface Options {
  iconSet?: IconSet;
  charSet?: string[];
  override?: boolean;
}

export const withIcons =
  ({iconSet = {}, charSet = [], override = false}: Options = {}): SlatePlugin =>
  (editor: Editor & IconsEditor) => {
    const {editableProps = {} as EditableProps} = editor;
    const {renderElement: Default = DefaultElement, onKeyDown} = editableProps;
    const {isInline, isVoid, markableVoid, addMark, removeMark, normalizeNode} = editor;

    const icons = override ? iconSet : {...defaultIconSet, ...iconSet};
    const chars = override ? charSet : [...defaultCharSet, ...charSet];
    editor.getIconSet = () => icons;
    editor.getCharSet = () => chars;

    // icon is inline, so override the following queries
    editor.isInline = (element: Element) => element.type === 'icon' || isInline(element);
    editor.isVoid = (element: Element) => element.type === 'icon' || isVoid(element);
    editor.markableVoid = (element: Element) => element.type === 'icon' || markableVoid(element);

    editor.normalizeNode = (entry: NodeEntry, options) => {
      const [node, path] = entry;
      if (IconsEditor.isIcon(editor, node) && normalizeIcon(editor, [node, path], options)) return;
      normalizeNode?.(entry, options);
    };

    /**
     * Overridden addMark command that considers marks in previous markableVoid element
     */
    editor.addMark = (key: string, value: unknown): void => {
      if (!editor.marks && SlateEditor.isPreviousPointInMarkableVoid(editor)) {
        editor.marks = {
          ...(FormatEditor.marks(editor) || {}),
          [key]: value
        };
      }

      return addMark(key, value);
    };

    /**
     * Overridden removeMark command that considers marks in previous markableVoid element
     */
    editor.removeMark = (key: string): void => {
      if (!editor.marks && SlateEditor.isPreviousPointInMarkableVoid(editor)) {
        const marks = {...(FormatEditor.marks(editor) || {})};
        delete marks[key];
        editor.marks = marks;
      }

      return removeMark(key);
    };

    editableProps.renderElement = (props: RenderElementProps) =>
      IconsEditor.isIcon(editor, props.element) ? (
        <Icon {...props} icon={props.element.icon} additionalClass={props.element.additionalClass}>
          {props.children}
        </Icon>
      ) : (
        <Default {...props}>{props.children}</Default>
      );

    editableProps.onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (
        isShortcut(e, [SlateHotkey.Enter, SlateHotkey.SoftBreak]) &&
        IconsEditor.isIconOnlySelected(editor)
      ) {
        // on enter move selection just after the icon
        e.preventDefault();
        Transforms.move(editor);
      }
      onKeyDown?.(e);
    };

    return editor;
  };
