import React from 'react';
import {useIntl} from 'react-intl';

import {list} from '../i18n';
import {ToggleList} from './ToggleList';
import {SlateHotkey} from '../../utils';
import {ListType} from '../../../Slate/interface';
import {withEditor} from '../withEditor';
import {ListEditor} from '../../plugins';

export const UnorderedList = withEditor(
  () => {
    const intl = useIntl();
    const title = intl.formatMessage(list.ul);
    return (
      <ToggleList
        type={ListType.UL}
        icon="list-ul"
        shortcut={SlateHotkey.UnorderedList}
        title={title}
      />
    );
  },
  e => ListEditor.isDefined(e, ListType.UL)
);
