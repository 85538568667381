import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';
import React, {type FC} from 'react';
import {type Editor} from 'slate';

import {type SlatePlugin} from './withPlugins';
import {type RenderContainerProps} from './core/react/withContainer';
import {PlaceholderTooltip} from '../components/PlaceholderTooltip';
import {SlateEditor} from './core';

export interface PlaceholderTooltipOptions
  extends Partial<
    Omit<
      TooltipProps,
      'visible' | 'onVisibilityChange' | 'overlay' | 'getTooltipContainer' | 'children'
    >
  > {
  overlayClassName?: string;
  placeholder?: (editor: Editor) => string | undefined;
}

export const withPlaceholderTooltip: (
  options?: PlaceholderTooltipOptions
) => SlatePlugin = options =>
  !options
    ? e => e
    : (editor: Editor & SlateEditor) => {
        const {placeholder = () => SlateEditor.placeholder(editor), ...tooltipProps} = options;
        const Container = editor.renderContainer;

        const Wrapper: FC<RenderContainerProps> = props => (
          <Container {...props}>
            <PlaceholderTooltip placeholder={placeholder} {...tooltipProps}>
              {props.children}
            </PlaceholderTooltip>
          </Container>
        );
        editor.renderContainer = Wrapper;
        return editor;
      };
