import {type Text, Transforms, Node} from 'slate';

import {type NormalizeEntryFn} from './interface';
import {FormatEditor} from '../../format';

export const normalizeText: NormalizeEntryFn<Text> = (editor, entry) => {
  const [node, path] = entry;
  const marks = Node.extractProps(node);

  if (Object.keys(marks).length) {
    const unsetProps = FormatEditor.filterProps(editor, node);
    if (unsetProps.length) {
      Transforms.unsetNodes(editor, unsetProps, {at: path, voids: true});
    }
  }
};
