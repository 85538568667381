import {type FC} from 'react';
import {Node} from 'slate';
import {useSlateStatic, type RenderLeafProps} from 'slate-react';

import {SlateLeaf} from 'components/SlateJS/components/SlateLeaf';

import {type FormatMarks} from '../../interface';
import {type FormatAttrs} from '../../definitions';
import {FormatEditor} from './FormatEditor';

import './FormatLeaf.scss';

export const FormatLeaf: FC<RenderLeafProps> = ({children, ...props}) => {
  const editor = useSlateStatic() as FormatEditor;
  const leafMarks = Node.extractProps(props.leaf);
  const lm = leafMarks as FormatMarks;
  const {className, style}: FormatAttrs = FormatEditor.formatMarkAttrs(editor, lm);
  return (
    <SlateLeaf {...props} className={className} style={style}>
      {children}
    </SlateLeaf>
  );
};
