import type React from 'react';

export const isEventHandled = <EventType extends React.SyntheticEvent<unknown, unknown>>(
  event: EventType,
  handler?: (event: EventType) => void | boolean
): boolean => {
  if (!handler) {
    return false;
  }
  // The custom event handler may return a boolean to specify whether the event
  // shall be treated as being handled or not.
  const shouldTreatEventAsHandled = handler(event);

  if (!shouldTreatEventAsHandled) {
    return !!shouldTreatEventAsHandled;
  }

  return event.isDefaultPrevented() || event.isPropagationStopped();
};
