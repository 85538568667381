import {useCallback} from 'react';
import {ReactEditor, useFocused, useSlateSelector, useSlateStatic} from 'slate-react';
import {shallowEqual} from 'react-redux';
import {useIntl} from 'react-intl';

import {type Shortcut} from 'helpers/shortcut';
import Icon from 'components/Icon';

import {Button} from './Button';
import {SlateHotkey} from '../../utils/hotkeys';
import {LinkEditor} from '../../plugins';
import {withEditor} from '../withEditor';
import {inline} from '../i18n';

interface Props {
  shortcut?: Shortcut;
}

export const ToggleLink = withEditor(({shortcut = SlateHotkey.Link}: Props) => {
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const intl = useIntl();
  const [isActive, isDisabled] = useSlateSelector(editor => {
    const isActive = LinkEditor.isLinkOnlySelected(editor);
    const canCreateLink = LinkEditor.canCreateLink(editor);
    const isDisabled = !isFocused || (!canCreateLink && !isActive);
    return [isActive, isDisabled];
  }, shallowEqual);

  const title = isActive ? intl.formatMessage(inline.activeLink) : intl.formatMessage(inline.link);

  const onClick = useCallback(() => {
    if (isDisabled) return;
    if (isActive) {
      LinkEditor.unlink(editor);
    } else if (LinkEditor.setCreatingLink(editor)) {
      ReactEditor.blur(editor);
    }
  }, [editor, isActive, isDisabled]);

  return (
    <Button
      shortcut={shortcut}
      active={isActive}
      disabled={isDisabled}
      onClick={onClick}
      title={title}
    >
      <Icon name={isActive ? 'unlink' : 'link'} />
    </Button>
  );
}, LinkEditor.is);
