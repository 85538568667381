import React, {type FC} from 'react';
import {useFocused, useSlateStatic} from 'slate-react';

import Icon from 'components/Icon';

import {Button, type ToolbarTooltipProps} from './Button';
import {IconsEditor} from '../../plugins';

type Props = ToolbarTooltipProps & {
  className?: string;
  icon: string;
};

export const InsertIcon: FC<Props> = ({className, children, icon, tooltip}) => {
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const isDisabled = !isFocused;

  const onClick = (e: React.MouseEvent) => {
    if (isDisabled) return;
    e.preventDefault();
    e.stopPropagation();
    tooltip?.()?.hide();
    IconsEditor.insertIcon(editor, icon);
  };

  return (
    <Button onClick={onClick} className={className}>
      {children || <Icon name={icon} />}
    </Button>
  );
};
