import {useEffect, useRef} from 'react';
import {Editor} from 'slate';
import {HistoryEditor} from 'slate-history';

import {type SlateEditorProps} from '../components/SlateEditor';

export const useEditorAutoNormalize = (
  editor: Editor,
  {normalizeOnMount = true}: SlateEditorProps
) => {
  const enabled = useRef(normalizeOnMount); // use only first prop value
  useEffect(() => {
    if (enabled.current) {
      Editor.normalize(editor, {force: true});
      if (HistoryEditor.isHistoryEditor(editor)) {
        editor.history.undos = []; // just for reset history after operations applied in normalizers
      }
    }
  }, [editor]);
};
