import {type RenderPlaceholderProps} from 'slate-react';
import {type CSSProperties, type FC} from 'react';

const placeholderDefaultStyles: CSSProperties = {
  // TODO: remove this one and move to scss???
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  maxWidth: '100%',
  display: 'block',
  // opacity: 0.333,
  userSelect: 'none',
  textDecoration: 'none'
};

export const SlatePlaceholder: FC<RenderPlaceholderProps> = ({children, ...props}) => (
  <div className="slate-placeholder" {...props.attributes} style={placeholderDefaultStyles}>
    {children}
  </div>
);
