import {type Editor} from 'slate';

import {type NormalizeEntryFn} from './interface';

export const normalizeEditor: NormalizeEntryFn<Editor> = (editor, [node]) => {
  if (node.children.length === 0) {
    // ensure that editor has at least one children element
    editor.children = [{children: [{text: ''}]}];
    editor.onChange();
    return true;
  }
  return;
};
