import React from 'react';
import classNames from 'classnames';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {PointerTooltip} from '../common/PointerTooltip';
import {PointerElementButton} from './PointerElementButton';

interface Props {
  align?: TooltipProps['align'];
  visible?: boolean;
  trigger?: string[];
  skip?: boolean;
  placement?: string;
  mouseLeaveDelay?: number;
  getTooltipContainer: () => HTMLElement;
  onVisibleChange: (visible: boolean) => void;
  renderTooltipOverlay?: () => React.ReactElement;
  tooltipClassName?: string;
  overlayClassName?: string;
  relatedElement?: string;
  children: React.ReactElement;
}

const align = {offset: [0, -10]};

export const PointerElementTooltip: React.FC<Props> = React.memo(
  ({
    children,
    skip,
    renderTooltipOverlay,
    tooltipClassName,
    overlayClassName,
    relatedElement,
    ...rest
  }) => {
    if (skip) {
      return children;
    }

    const overlay = renderTooltipOverlay ? (
      <div className="custom">
        <div className="custom__overlay">{renderTooltipOverlay?.()}</div>
        <div className="custom__button">
          <PointerElementButton elementId={children.props.id} relatedElement={relatedElement} />
        </div>
      </div>
    ) : (
      <PointerElementButton elementId={children.props.id} relatedElement={relatedElement} />
    );

    const className = classNames('x-player-pointer-tooltip', overlayClassName, {
      'x-player-pointer-tooltip--custom': overlayClassName
    });

    if (tooltipClassName) {
      return (
        <div className={tooltipClassName}>
          <PointerTooltip overlayClassName={className} overlay={overlay} align={align} {...rest}>
            {children}
          </PointerTooltip>
        </div>
      );
    }

    return (
      <PointerTooltip overlayClassName={className} overlay={overlay} {...rest}>
        {children}
      </PointerTooltip>
    );
  }
);
