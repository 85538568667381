import {type Editor} from 'slate';

import {type SlatePlugin} from '../withPlugins';
import {genKey} from '../../../Slate/utils';
import {type Mutable} from '../../interface';
import {type SlateEditor} from './SlateEditor';

export const withSlateEditor: SlatePlugin = (editor: Mutable<Editor & SlateEditor>) => {
  const slateEditorId = genKey();
  editor.getEditorId = () => slateEditorId;
  editor.getEditorProps = () => null;

  return editor;
};
