import {Editor} from 'slate';
import React from 'react';

import {isAndroid, isMobileWebView} from 'helpers/browser';

import {type SlatePlugin} from '../withPlugins';
import {SlateLeaf} from '../../components/SlateLeaf';
import {type EditablePropsEditor} from '../core/react/withEditableProps';

interface Options {
  enabled?: boolean;
}

/**
 * This plugin's target is to fix an input behaviour at the start of the empty inline input editor.
 * It fixes initial selection on focus empty inline input,
 * so that the IME input chars appear in the text node instead of editor root div block
 *
 * Should be enabled only on an Android device and NOT in a mobile app's webview
 *
 * @experimental
 * @see https://redmine.englex.ru/issues/12441#note-2 for details
 */
export const withInlineInputEmptyLeaf =
  ({enabled = isAndroid && !isMobileWebView()}: Options = {}): SlatePlugin =>
  editor => {
    if (!enabled) {
      return editor;
    }
    const e = editor as Editor & EditablePropsEditor;
    const {editableProps = {}} = e;
    const {renderLeaf: Leaf = SlateLeaf} = editableProps;

    editableProps.renderLeaf = props => {
      const isEmptyEditor = !Editor.string(e, []).length;

      if (isEmptyEditor) {
        const zeroWidthNonBreakingSpace = '\uFEFF';
        return (
          <Leaf {...props}>
            <span data-slate-zero-width="n" data-slate-length="0">
              {zeroWidthNonBreakingSpace}
              {props.children}
            </span>
          </Leaf>
        );
      }
      return <Leaf {...props}>{props.children}</Leaf>;
    };

    return e;
  };
