import React, {useLayoutEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {type Editor} from 'slate';

import {type SlateEditorProps} from '../components/SlateEditor';
import {DefaultToolbar} from '../components/toolbar/DefaultToolbar';

const defaultToolbar: SlateEditorProps['toolbar'] = {hide: false};

export const useEditorChildren = (
  editor: Editor,
  {toolbar = defaultToolbar, children: childrenProp}: SlateEditorProps
) => {
  const [toolbarPortalNode, setToolbarPortalNode] = useState<HTMLElement | null>(null);

  const {hide, portalId} = toolbar;
  useLayoutEffect(() => {
    setToolbarPortalNode(!hide && portalId ? document.getElementById(portalId) : null);
  }, [hide, portalId]);

  const children = !hide ? childrenProp || <DefaultToolbar inPortal={!!portalId} /> : null;

  return children && toolbarPortalNode ? (
    <Portal node={toolbarPortalNode}>{children}</Portal>
  ) : (
    children
  );
};
