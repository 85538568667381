import React, {type FC} from 'react';
import {useFocused, useSlateSelector, useSlateStatic} from 'slate-react';
import {Editor} from 'slate';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {FormatEditor} from '../../plugins';
import {Button, type ToolbarButtonProps} from './Button';
import {format} from '../i18n';
import {withEditor} from '../withEditor';
import {type FormatAttrs, type MarkType} from '../../definitions';
import styles from './Format.module.scss';

type Props = ToolbarButtonProps & {
  className?: string;
  mark: MarkType;
  value?: unknown | null; // set null for removing mark
  applyAttrs?: boolean;
};

export const Format: FC<Props> = ({
  className,
  children,
  mark,
  value,
  tooltip,
  applyAttrs,
  title: titleProp,
  ...props
}) => {
  const shouldRemoveMark = value === null;
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const intl = useIntl();

  const isDisabled = !isFocused;
  const isActiveMark = useSlateSelector(editor => {
    return shouldRemoveMark
      ? FormatEditor.isInactiveMark(editor, mark)
      : FormatEditor.isActiveMark(editor, mark, {value});
  });
  const isActive = !isDisabled && isActiveMark;

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isDisabled) return;

    tooltip?.()?.hide();

    if (shouldRemoveMark && isActive) return;

    shouldRemoveMark
      ? Editor.removeMark(editor, mark)
      : FormatEditor.toggleMark(editor, mark, value);
  };
  const {className: attrsClassName, style}: FormatAttrs =
    (applyAttrs && FormatEditor.formatMarkAttrs(editor, {[mark]: value})) || {};

  const title = titleProp === null ? undefined : titleProp || intl.formatMessage(format[mark]);
  const shortcut = FormatEditor.shortcut(editor, mark);

  return (
    <Button
      onMouseDown={onMouseDown}
      active={isActive}
      className={classNames(styles.button, className, attrsClassName, {marked: applyAttrs})}
      style={style}
      title={title}
      shortcut={shortcut}
      {...props}
      tooltip={tooltip}
    >
      {children}
    </Button>
  );
};

export const Bold = withEditor(
  () => <Format mark="bold" icon="bold" value={true} />,
  e => FormatEditor.hasFormatMark(e, 'bold')
);

export const Italic = withEditor(
  () => <Format mark="italic" icon="italic" value={true} />,
  e => FormatEditor.hasFormatMark(e, 'italic')
);

export const Underline = withEditor(
  () => <Format mark="underline" icon="underline" value={true} />,
  e => FormatEditor.hasFormatMark(e, 'underline')
);

export const StrikeThrough = withEditor(
  () => <Format mark="strikeThrough" icon="strikethrough" value={true} />,
  e => FormatEditor.hasFormatMark(e, 'strikeThrough')
);
