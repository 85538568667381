import {type ListBlockDef} from './withList';
import {ListStyleType, ListType} from '../../../Slate/interface';
import {SlateHotkey} from '../../utils';

export const ol: ListBlockDef = {
  block: 'list',
  props: {list: ListType.OL},
  shortcut: SlateHotkey.OrderedList
};
export const ul: ListBlockDef = {
  block: 'list',
  props: {list: ListType.UL},
  shortcut: SlateHotkey.UnorderedList
};

export const alpha: ListBlockDef = {
  block: 'list',
  props: {list: ListType.OL, listStyleType: ListStyleType.ALPHA},
  shortcut: SlateHotkey.OrderedListAlpha,
  attrs: {
    className: 'alpha'
  }
};

export const nestedLists: ListBlockDef[] = [ol, ul, alpha];
