import {type RenderLeafProps} from 'slate-react';
import {type CSSProperties} from 'react';

// The following style is a workaround for a Chromium bug where,
// if you have an inline at the end of a block,
// clicking the end of a block puts the cursor inside the inline
// instead of inside the final {text: ''} node
// https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
const padding = (style: CSSProperties | undefined, empty?: boolean): CSSProperties | undefined =>
  empty ? {...style, paddingLeft: '0.001em', backgroundColor: 'initial'} : style;

interface Props extends RenderLeafProps {
  className?: string;
  style?: CSSProperties;
}

export const SlateLeaf = (props: Props) => {
  const {attributes, children, leaf, style, text, ...rest} = props;
  return (
    <span style={padding(style, leaf.text === '')} {...attributes} {...rest}>
      {children}
    </span>
  );
};
