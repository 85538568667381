import {type CombineProps, type PropsWithRender} from './PointerElementListener';

function isRenderProps(props: CombineProps): props is PropsWithRender {
  return (
    typeof (props as PropsWithRender).render === 'function' &&
    !!(props as PropsWithRender).elementId
  );
}

export function getElementId(props: CombineProps) {
  if (isRenderProps(props)) {
    return props.elementId;
  }

  return props.children?.props.id;
}

export function getElement(
  props: CombineProps,
  elementId: string,
  isAvailable: boolean,
  isTeacher: boolean
) {
  if (isRenderProps(props)) {
    return props.render({
      isAvailable,
      isTeacher,
      pointerClassName: 'pointer-listener',
      pointerInlineClassName: 'pointer-listener--inline'
    });
  }

  return props.children;
}
