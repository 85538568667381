import React, {type RefObject} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {type AppState, type SelectionData} from 'store/interface';
import {type WidgetProperties} from 'store/exercise/player/interface';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';

import useCanPlayTogether from '../../media/_common/useCanPlayTogether';
import {getElement, getElementId} from './helpers';
import {PointerElement} from './PointerElement';

import './PointerElement.scss';

const isDefaultAvailable = () => true;

export interface PointerListenerProps {
  onClick?: (props?: unknown) => void;
  onAnimationStart?: (event: React.SyntheticEvent) => void;
  onAnimationEnd?: (event: React.SyntheticEvent) => void;
  onMouseEnter?: (event: React.SyntheticEvent) => void;
  onMouseLeave?: (event: React.SyntheticEvent) => void;
}

interface BaseProps {
  widgetId?: string;
  ref?: PointerListenerRef;
  passive?: boolean;
  trigger?: string[];
  align?: TooltipProps['align'];
  placement?: string;
  tooltipClassName?: string;
  overlayClassName?: string;
  isAvailable?: () => boolean;
  onSelected?: (callback: () => void) => void;
  renderTooltipOverlay?: () => React.ReactElement;
  getTooltipContainer?: () => HTMLElement;
  mouseLeaveDelay?: number;
  relatedElement?: string;
  preview?: boolean;
  inline?: boolean;
}

interface PropsWithChildren extends BaseProps {
  children: React.ReactElement;
}

interface RenderProps {
  isAvailable: boolean;
  isTeacher: boolean;
  pointerClassName: string;
  pointerInlineClassName: string;
}

export interface PropsWithRender extends BaseProps {
  elementId: string;
  render: (props: RenderProps) => React.ReactElement;
}

export type CombineProps = PropsWithChildren | PropsWithRender;

export type PointerListenerRef = RefObject<{
  isVisibleTooltip: () => boolean;
  showTooltip: () => void;
  hideTooltip: () => void;
}>;

export const PointerElementListener: React.FC<CombineProps> = React.memo(
  React.forwardRef(
    (
      {
        widgetId,
        renderTooltipOverlay,
        getTooltipContainer,
        tooltipClassName,
        overlayClassName,
        relatedElement,
        mouseLeaveDelay,
        passive,
        align,
        trigger,
        placement,
        preview,
        inline,
        isAvailable = isDefaultAvailable,
        onSelected,
        ...props
      }: CombineProps,
      ref: PointerListenerRef
    ) => {
      const {studentTeacherId} = useParams<{studentTeacherId: string}>();

      const widget = useSelector<AppState>(state => {
        const widgets = state.xplayer?.exercises.reduce<WidgetProperties[]>(
          (result: WidgetProperties[], exercise: ExerciseProperties) => {
            return result.concat(...exercise.widgets.toArray());
          },
          []
        );

        return widgets?.find(w => w.id === widgetId);
      });

      const isNotTeacher = useSelector<AppState, boolean>(state => state.user?.role !== 'teacher');

      const inCall = useSelector<AppState, boolean>(state => Boolean(state.rtc.callInProgress));

      const selectionData = useSelector<AppState, SelectionData | undefined>(
        state => state.classroom?.selection
      );

      const canOpenTogether = useCanPlayTogether({preview, studentTeacherId});

      const getIsAvailable = () => {
        if (preview) return false;

        if (Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED)) return true;

        const isActiveCall = isNotTeacher ? id && inCall : id && canOpenTogether;

        return widget ? isActiveCall && isAvailable() : isActiveCall;
      };

      const id = getElementId(props);
      const isAvailablePointer = getIsAvailable();
      const element = getElement(props, id, isAvailablePointer, !isNotTeacher);

      return isAvailablePointer ? (
        <PointerElement
          ref={ref}
          passive={passive}
          trigger={trigger}
          align={align}
          isNotTeacher={isNotTeacher}
          selectionData={selectionData}
          tooltipClassName={tooltipClassName}
          overlayClassName={overlayClassName}
          mouseLeaveDelay={mouseLeaveDelay}
          renderTooltipOverlay={renderTooltipOverlay}
          getTooltipContainer={getTooltipContainer}
          relatedElement={relatedElement}
          placement={placement}
          inline={inline}
          onSelected={onSelected}
        >
          {element}
        </PointerElement>
      ) : (
        element
      );
    }
  )
);
