import {type BaseEditor, Editor, Element, type Location, Path, Range, Transforms} from 'slate';

import {type FormatMarks, type IconData, type IconInline} from '../../interface';
import {FormatEditor} from '../format';

export interface IconSet {
  [key: string]: string;
}

export interface IconsEditor extends BaseEditor {
  getIconSet?: () => Readonly<IconSet>;
  getCharSet?: () => Readonly<string[]>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IconsEditor = {
  isIcon(editor: Editor, node: unknown | IconInline): node is IconInline {
    return Element.isElement(node) && editor.isInline(node) && node.type === 'icon';
  },
  getIconNames(editor: Editor): Readonly<string[]> {
    const names = editor.getIconSet?.() || [];
    return Object.keys(names);
  },
  getChars(editor: Editor): Readonly<string[]> {
    return editor.getCharSet?.() || [];
  },
  getIcon(editor: Editor, icon: string): string | undefined {
    return editor.getIconSet?.()[icon];
  },
  hasIcons(editor: Editor, {at = editor.selection}: {at?: Location | null} = {}): boolean {
    if (!at) return false;
    const [matches] = Editor.nodes(editor, {
      match: (n: Element) => IconsEditor.isIcon(editor, n)
    });
    return !!matches;
  },
  isIconOnlySelected(editor: Editor): boolean {
    const {selection} = editor;
    if (!selection || Range.isExpanded(selection)) return false;

    const [, path] = Editor.node(editor, selection);
    const [mayBeIcon] = Editor.parent(editor, path, {edge: 'end'});
    return IconsEditor.isIcon(editor, mayBeIcon);
  },
  create(editor: Editor, icon: string, marks?: FormatMarks | null): IconInline {
    return {type: 'icon', icon, children: [{...marks, text: ''}]};
  },
  filterProps(editor: Editor, icon: IconInline): string[] {
    const {type, children, ...iconProps} = icon;
    const allowedKeys: (keyof IconData)[] = ['icon', 'additionalClass'];

    return Object.keys(iconProps).filter(k => !allowedKeys.includes(k as never));
  },
  insertIcon(editor: Editor, icon: string) {
    if (!editor.selection) return;
    const marks = FormatEditor.marks(editor);
    const node = IconsEditor.create(editor, icon, marks);
    const endPoint = Range.end(editor.selection);
    const parentPath = Path.parent(endPoint.path);

    const isEnd = Editor.hasPath(editor, parentPath) && Editor.isEnd(editor, endPoint, parentPath);

    let shouldMoveCursorAfterInsert = !isEnd;

    if (shouldMoveCursorAfterInsert) {
      // we should not move selection when the next node is void inline
      const next = Editor.next(editor, {
        mode: 'lowest',
        voids: true,
        match: (n, p) =>
          Path.equals(Path.next(endPoint.path), p) &&
          Element.isElement(n) &&
          Editor.isInline(editor, n) &&
          Editor.isVoid(editor, n)
      });
      const nextIsVoidInline = !!next;
      shouldMoveCursorAfterInsert = !nextIsVoidInline;
    }

    Transforms.insertFragment(editor, [{...marks, text: ''}, node, {...marks, text: ''}]);
    shouldMoveCursorAfterInsert && Transforms.move(editor);
  }
};
