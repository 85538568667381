import React, {type FC} from 'react';
import {useIntl} from 'react-intl';
import {useSlateSelector} from 'slate-react';
import {shallowEqual} from 'react-redux';

import {TooltipToggle} from './TooltipToggle';
import {list} from '../i18n';
import Icon from '../../../Icon';
import {Toolbar} from './Toolbar';
import {ToggleList} from './ToggleList';
import {SlateHotkey} from '../../utils';
import {type ToolbarTooltipProps} from './Button';
import {ListStyleType, ListType} from '../../../Slate/interface';
import {ListEditor} from '../../plugins';
import {withEditor} from '../withEditor';

const OrderedNumberList = withEditor(
  ({tooltip}: ToolbarTooltipProps) => {
    const intl = useIntl();
    const title = intl.formatMessage(list.decimal);
    return (
      <ToggleList
        type={ListType.OL}
        icon="list-numbers"
        shortcut={SlateHotkey.OrderedList}
        tooltip={tooltip}
        title={title}
      />
    );
  },
  e => ListEditor.isDefined(e, ListType.OL)
);

const OrderedAlphaList = withEditor(
  ({tooltip}: ToolbarTooltipProps) => {
    const intl = useIntl();
    const title = intl.formatMessage(list.alpha);
    return (
      <ToggleList
        type={ListType.OL}
        listStyleType={ListStyleType.ALPHA}
        icon="list-letters"
        shortcut={SlateHotkey.OrderedListAlpha}
        tooltip={tooltip}
        title={title}
      />
    );
  },
  e => ListEditor.isDefined(e, ListType.OL, ListStyleType.ALPHA)
);

const Overlay: FC<ToolbarTooltipProps> = ({tooltip}) => {
  return (
    <Toolbar>
      <OrderedNumberList tooltip={tooltip} />
      <OrderedAlphaList tooltip={tooltip} />
    </Toolbar>
  );
};

export const OrderedListSelector = withEditor(
  () => {
    const intl = useIntl();
    const title = intl.formatMessage(list.ol);
    const [active, disabled] = useSlateSelector(
      editor => [
        ListEditor.isActive(editor, ListType.OL),
        ListEditor.isDisabled(editor, ListType.OL)
      ],
      shallowEqual
    );
    return (
      <TooltipToggle Overlay={Overlay} title={title} active={active} disabled={disabled}>
        <Icon name="list-ol" />
      </TooltipToggle>
    );
  },
  e =>
    ListEditor.isDefined(e, ListType.OL) ||
    ListEditor.isDefined(e, ListType.OL, ListStyleType.ALPHA)
);
