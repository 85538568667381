import {Editor, Element, type Node, Transforms} from 'slate';
import {DefaultElement, type RenderElementProps} from 'slate-react';
import React from 'react';

import {type BlockDef} from 'components/SlateJS/definitions';

import {type SlatePlugin} from '../withPlugins';
import {SlateEditor} from '../core';
import {ExampleBlock} from './ExampleBlock';
import {type EditableProps, type ExampleElement} from '../../interface';
import {normalizeExample} from './normalizeExample';
import {SlateHotkey} from '../../utils';
import isShortcut, {type Shortcut} from '../../../../helpers/shortcut';

interface Options {
  shortcut?: Shortcut | null; // null value = disable shortcut
}

export const withExample =
  ({shortcut = SlateHotkey.ToggleExample}: Options = {}): SlatePlugin =>
  (e: Editor) => {
    SlateEditor.defineBlock(e, {block: 'example', shortcut: shortcut || undefined});

    const {editableProps = {} as EditableProps} = e;
    const {onKeyDown, renderElement: Default = DefaultElement} = editableProps;

    const {normalizeNode} = e;
    e.normalizeNode = (entry, options) => {
      const [node, path] = entry;
      if (ExampleEditor.isExample(e, node) && normalizeExample(e, [node, path], options)) return;

      normalizeNode(entry, options);
    };

    editableProps.onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const sc = ExampleEditor.shortcut(e);
      if (sc && isShortcut(event, sc)) {
        event.preventDefault();
        ExampleEditor.toggle(e);
      }
      onKeyDown?.(event);
    };

    editableProps.renderElement = (props: RenderElementProps) => {
      const Block = ExampleEditor.isExample(e, props.element) ? ExampleBlock : Default;
      return <Block {...props}>{props.children}</Block>;
    };

    return e;
  };

export const ExampleEditor = {
  is(e: Editor): boolean {
    return !!SlateEditor.defRegistry(e)?.blocks?.some(b => b.block === 'example');
  },
  isExample(e: Editor, element: Node): element is ExampleElement {
    return Element.isElement(element) && Editor.isBlock(e, element) && element.type === 'example';
  },
  isActive(editor: Editor): boolean {
    const {selection} = editor;
    if (!selection) return false;

    const exampleNodes = Editor.nodes(editor, {
      match: n => ExampleEditor.isExample(editor, n)
    });

    const [node] = exampleNodes;
    return !!node;
  },
  isDisabled(editor?: Editor): boolean {
    return false;
  },
  toggle(editor: Editor): void {
    if (ExampleEditor.isActive(editor)) {
      Transforms.unwrapNodes(editor, {
        match: n => Element.isElement(n) && ExampleEditor.isExample(editor, n)
      });
    } else {
      Transforms.wrapNodes(editor, ExampleEditor.create(), {mode: 'highest'});
    }
  },
  create(): ExampleElement {
    return {
      type: 'example',
      children: []
    };
  },
  shortcut(e: Editor): Shortcut | undefined {
    const blocks = SlateEditor.defRegistry(e)?.blocks;
    const block = blocks?.find(b => b.block === 'example') as BlockDef;
    return block.shortcut;
  }
};
