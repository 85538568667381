import React, {type FC} from 'react';
import {useSlateSelector, useSlateStatic} from 'slate-react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {shallowEqual} from 'react-redux';

import Icon from 'components/Icon';

import {TooltipToggle} from './TooltipToggle';
import {Toolbar} from './Toolbar';
import {type ToolbarTooltipProps} from './Button';
import {Format} from './Format';
import {FormatEditor} from '../../plugins';
import {format} from '../i18n';
import {withEditor} from '../withEditor';
import styles from './HighlightSelector.module.scss';

const Highlights: FC<ToolbarTooltipProps> = ({tooltip}) => {
  const editor = useSlateStatic();
  const highlights = FormatEditor.formats(editor).highlight || [];
  return (
    <>
      {highlights.map(({value, attrs}) => (
        <Format
          key={String(value)}
          mark="highlight"
          icon={['times-circle', 'circle']}
          value={value}
          tooltip={tooltip}
          className={classNames(styles.btn, attrs.className)}
          title={null}
        />
      ))}
    </>
  );
};

const Overlay: FC<ToolbarTooltipProps> = ({tooltip}) => {
  return (
    <Toolbar>
      <Highlights tooltip={tooltip} />
    </Toolbar>
  );
};

export const HighlightSelector = withEditor(
  () => {
    const intl = useIntl();
    const [active, className] = useSlateSelector(editor => {
      const {highlight} = FormatEditor.activeMarks(editor) || {};
      const active = !!highlight;
      const {className} = FormatEditor.formatMarkAttrs(editor, {highlight});
      return [active, className];
    }, shallowEqual);

    const title = intl.formatMessage(format.highlight);

    return (
      <TooltipToggle
        Overlay={Overlay}
        title={title}
        active={active}
        className={classNames(styles.toggle, className)}
      >
        <Icon name="virc-border-color" />
      </TooltipToggle>
    );
  },
  e => !!FormatEditor.formats(e).highlight?.length
);
