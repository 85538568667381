import {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {type Descendant, Editor} from 'slate';
import classNames from 'classnames';
import {withHistory} from 'slate-history';

import {isSafari} from 'helpers/browser';

import {SlateEditor, type SlateEditorProps} from '../SlateEditor';
import {valueFromText} from '../../utils/valueFromText';
import genKey from '../../../Slate/utils/genKey';
import {
  type PlaceholderTooltipOptions,
  type SlatePlugin,
  withContainer,
  withDisabledScrollIntoView,
  withInlineInput,
  withInlineInputEmptyLeaf,
  withPlaceholderTooltip,
  withTextNormalizer
} from '../../plugins';
import './InlineInput.scss';

interface Props extends Omit<SlateEditorProps, 'editor' | 'onChange' | 'initialValue' | 'value'> {
  id?: string;
  text?: string;
  plugins?: SlatePlugin[];
  focusHidesPlaceholder?: boolean;
  preview?: boolean;
  placeholderTooltip?: PlaceholderTooltipOptions;
  onChange?(text: string, value: Descendant[]): void;
}

const toolbar = {hide: true};
export const InlineInput: FC<Props> = ({
  id,
  preview,
  onChange: onChangeProp,
  placeholder,
  focusHidesPlaceholder,
  text,
  placeholderTooltip,
  plugins: pluginsProp,
  readOnly,
  ...rest
}) => {
  const textRef = useRef(text);
  const initKey = useMemo(() => genKey(), []);
  const [value, setValue] = useState(valueFromText(text));
  const [key, setKey] = useState(initKey);

  const plugins: SlatePlugin[] = useMemo(() => {
    const className = (editor: Editor, isFocused: boolean) => {
      return classNames('x-inline-input', {
        empty: editor.children.length === 0 || !Editor.string(editor, []).length,
        placeholder: !!placeholder,
        safari: isSafari() && !readOnly,
        'focus-hides-placeholder': focusHidesPlaceholder && isFocused
      });
    };
    const withCustomContainer = withContainer({className});

    return [
      withHistory,
      withCustomContainer,
      withInlineInput,
      withTextNormalizer({stripChars: '\n'}),
      withPlaceholderTooltip(placeholderTooltip),
      withInlineInputEmptyLeaf(),
      withDisabledScrollIntoView(),
      ...(pluginsProp || [])
    ];
  }, [focusHidesPlaceholder, placeholder, placeholderTooltip, pluginsProp, readOnly]);

  const onChange = useCallback(
    (value: Descendant[], editor: Editor) => {
      const text = Editor.string(editor, []);
      textRef.current = text;
      onChangeProp?.(text, value);
    },
    [onChangeProp]
  );

  useEffect(() => {
    // if text prop has updated externally we recreate value from text
    // but if it has been updated internally - do nothing
    if (text !== undefined && text !== textRef.current) {
      const value = valueFromText(text);
      textRef.current = text;
      setValue(value);
      setKey(genKey());
    }
  }, [text]);

  return (
    <SlateEditor
      key={key}
      plugins={plugins}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      toolbar={toolbar}
      skipSelectionChange={true}
      readOnly={readOnly}
      {...rest}
    />
  );
};
