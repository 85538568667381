import React, {type FC} from 'react';
import {useReadOnly, useSelected, useSlateSelector} from 'slate-react';

import {type LinkElementProps} from './interface';
import {Link} from './Link';
import {EditLink} from './EditLink';
import {LinkEditor} from './LinkEditor';

export const LinkElement: FC<LinkElementProps> = props => {
  const selected = useSelected();
  const readOnly = useReadOnly();
  const isLinkOnlySelected = useSlateSelector(LinkEditor.isLinkOnlySelected);

  if (!readOnly && selected && isLinkOnlySelected) {
    return <EditLink {...props}>{props.children}</EditLink>;
  }

  return <Link {...props}>{props.children}</Link>;
};
