import React, {type MouseEvent, useCallback} from 'react';
import {Editor, Transforms} from 'slate';
import {ReactEditor, type RenderPlaceholderProps, useSlateStatic} from 'slate-react';

import {SlateEditor} from '../../plugins/core/SlateEditor';

import './InlineInputPlaceholder.scss';

export const InlineInputPlaceholder = ({
  children,
  attributes: {style, ...attributes}
}: RenderPlaceholderProps) => {
  const editor = useSlateStatic();
  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      // Sometimes editor is focused, but DOM selection is at the first next text node after the editor.
      // If so, try to select at the end of the editor
      e.preventDefault();
      e.stopPropagation();
      ReactEditor.focus(editor);
      setTimeout(() => {
        if (!SlateEditor.isDOMSelectionInEditor(editor)) {
          ReactEditor.deselect(editor);
          setTimeout(() => {
            Transforms.select(editor, Editor.end(editor, []));
          });
        }
      });
    },
    [editor]
  );

  return (
    <>
      <span data-slate-zero-width="n" data-slate-length="0">
        {'\uFEFF'}
      </span>
      <span className="inline-input-placeholder" {...attributes} onMouseDown={onMouseDown}>
        {children}
      </span>
    </>
  );
};
