import React, {type FC, type PropsWithChildren} from 'react';
import {type Editor} from 'slate';
import {useSlateStatic} from 'slate-react';

/**
 * HOC withEditor
 *
 * Just a helper HOC for the component conditional rendering
 * @param Component component to wrap
 * @param isEditor predicate
 */
export function withEditor<P extends {} = {}>(
  Component: FC<P>,
  isEditor: <E extends Editor = Editor>(editor: E) => boolean = () => true
): FC<P> {
  const WithEditor: FC<P> = (props: PropsWithChildren<P>) => {
    const editor = useSlateStatic();
    const shouldRender = isEditor(editor);
    return shouldRender ? <Component {...props} /> : null;
  };
  WithEditor.displayName = `withEditor(${Component.name})`;
  return WithEditor;
}
