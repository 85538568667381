import React, {type FC} from 'react';
import {useSlateSelector, useSlateStatic} from 'slate-react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {shallowEqual} from 'react-redux';

import Icon from 'components/Icon';

import {TooltipToggle} from './TooltipToggle';
import {Toolbar} from './Toolbar';
import {type ToolbarTooltipProps} from './Button';
import {Format} from './Format';
import {FormatEditor} from '../../plugins';
import {format} from '../i18n';
import {withEditor} from '../withEditor';
import styles from './FontColorSelector.module.scss';

const Colors: FC<ToolbarTooltipProps> = ({tooltip}) => {
  const editor = useSlateStatic();
  const colors = FormatEditor.formats(editor).color || [];
  return (
    <>
      {colors.map(({value, attrs}) => (
        <Format
          key={String(value)}
          mark="color"
          icon={['times-circle', 'circle']}
          value={value}
          tooltip={tooltip}
          className={classNames(styles.btn, attrs.className)}
          title={null}
        />
      ))}
    </>
  );
};

const Overlay: FC<ToolbarTooltipProps> = ({tooltip}) => {
  return (
    <Toolbar>
      <Colors tooltip={tooltip} />
    </Toolbar>
  );
};

export const FontColorSelector = withEditor(
  () => {
    const intl = useIntl();
    const [active, className] = useSlateSelector(editor => {
      const {color} = FormatEditor.activeMarks(editor) || {};
      const active = !!color;
      const {className} = FormatEditor.formatMarkAttrs(editor, {color});
      return [active, className];
    }, shallowEqual);
    const title = intl.formatMessage(format.color);

    return (
      <TooltipToggle
        Overlay={Overlay}
        title={title}
        active={active}
        className={classNames(styles.toggle, className)}
      >
        <Icon name="virc-text-color" />
      </TooltipToggle>
    );
  },
  e => !!FormatEditor.formats(e).color?.length
);
