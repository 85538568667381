import {type BaseEditor, type Editor} from 'slate';
import {DefaultElement} from 'slate-react';

import {type EditableProps, type Mutable} from 'components/SlateJS/interface';

import {SlatePlaceholder} from '../../../components/SlatePlaceholder';

export interface EditablePropsEditor extends BaseEditor {
  readonly editableProps: EditableProps;
}

export const withEditableProps = <E extends Editor>(editor: E): E & EditablePropsEditor => {
  const e = editor as Mutable<E & EditablePropsEditor>;
  const editableProps: EditableProps = {};

  editableProps.renderElement = DefaultElement;
  editableProps.renderPlaceholder = ({children, ...props}) => (
    <SlatePlaceholder {...props}>{children}</SlatePlaceholder>
  );

  e.editableProps = editableProps;

  return e;
};
