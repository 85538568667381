import {Transforms} from 'slate';

import {type NormalizeEntryFn} from '../core';
import {type IconInline} from '../../interface';
import {IconsEditor} from './IconsEditor';

export const normalizeIcon: NormalizeEntryFn<IconInline> = (editor, [node, path]) => {
  if (!node.icon) {
    // unknown icon name, so removing the broken icon completely
    Transforms.removeNodes(editor, {at: path});
    return true;
  }
  if (node.children.length > 1) {
    // strange children count, should be the only empty text node, so unwrapping the icon node to preserve its children
    Transforms.unwrapNodes(editor, {at: path, voids: true});
    return true;
  }

  const unsetProps = IconsEditor.filterProps(editor, node);
  if (unsetProps.length) {
    // icon has unsupported props, so just unset them
    Transforms.unsetNodes(editor, unsetProps, {at: path});
  }
  return;
};
