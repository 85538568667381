import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {MessageType, usePublishPointer} from '../common/usePublishPointer';
import {setPointerSelection} from '../../../common/action';
import {PointerButton} from '../common/PointerButton';

interface Props {
  elementId: string;
  relatedElement?: string;
}

export function PointerElementButton({elementId, relatedElement}: Props) {
  const inCall = useSelector<AppState, boolean>(state => Boolean(state.rtc.callInProgress));

  const dispatch = useDispatch();

  const publishPointer = usePublishPointer();

  const onClick = useCallback(() => {
    const data = {elementId, relatedElement};

    if (!inCall && Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED)) {
      return new Promise(resolve =>
        setTimeout(() => resolve(dispatch(setPointerSelection(data))), 300)
      );
    }

    return publishPointer(MessageType.Element, data, () => dispatch(setPointerSelection(data)));
  }, [dispatch, inCall, publishPointer, elementId, relatedElement]);

  return <PointerButton onClick={onClick} />;
}
