import {type ToolsConfig} from '@englex/paint-react';

export const config: ToolsConfig = {
  Pencil: {stroke: 'red', strokeWidth: 3},
  Highlighter: {stroke: 'red', strokeWidth: 20},
  Arrow: {
    stroke: 'red',
    fill: 'red',
    lineCap: 'round',
    pointerLength: 9,
    pointerWidth: 6,
    strokeWidth: 3
  },
  Rect: {stroke: 'red', strokeWidth: 3},
  Text: {fill: 'red', lineHeight: 1.667, fontSize: 18}
};
