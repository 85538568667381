import React, {type FC} from 'react';
import {useIntl} from 'react-intl';
import {useSlateSelector} from 'slate-react';

import Icon from 'components/Icon';

import {TooltipToggle} from './TooltipToggle';
import {Toolbar} from './Toolbar';
import {type ToolbarTooltipProps} from './Button';
import {Format} from './Format';
import {FormatEditor} from '../../plugins';
import {format} from '../i18n';
import {withEditor} from '../withEditor';

const FontSizes: FC<ToolbarTooltipProps> = ({tooltip}) => {
  const intl = useIntl();
  return (
    <>
      <Format
        mark="fontSize"
        value="largest"
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeLargest'})}
        </span>
      </Format>
      <Format
        mark="fontSize"
        value="large"
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeLarge'})}
        </span>
      </Format>
      <Format
        mark="fontSize"
        value={null}
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeDefault'})}
        </span>
      </Format>
      <Format
        mark="fontSize"
        value="small"
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeSmall'})}
        </span>
      </Format>
      <Format
        mark="fontSize"
        value="smaller"
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeSmaller'})}
        </span>
      </Format>
      <Format
        mark="fontSize"
        value="smallest"
        className="labeled sfs"
        tooltip={tooltip}
        applyAttrs={true}
        title={null}
      >
        <span className="title">
          {intl.formatMessage({id: 'Slate.Toolbar.Button.FontSizeSmallest'})}
        </span>
      </Format>
    </>
  );
};

const Overlay: FC<ToolbarTooltipProps> = ({tooltip}) => {
  return (
    <Toolbar>
      <FontSizes tooltip={tooltip} />
    </Toolbar>
  );
};

export const FontSizeSelector = withEditor(
  () => {
    const intl = useIntl();
    const active = useSlateSelector(editor => FormatEditor.isActiveMark(editor, 'fontSize'));
    const title = intl.formatMessage(format.fontSize);

    return (
      <TooltipToggle Overlay={Overlay} title={title} vertical={true} active={active}>
        <Icon name="text-height" />
      </TooltipToggle>
    );
  },
  e => !!FormatEditor.formats(e).fontSize?.length
);
