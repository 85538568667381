import {Editor, Element, Path, Range, Text, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';

import {type CreateLinkDecoration, type LinkInline, type SlateLinkData} from '../../interface';

export interface LinkEditor {
  creatingLink?: (create?: boolean) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinkEditor = {
  is(editor: Editor): editor is Editor & LinkEditor {
    return !!editor?.creatingLink;
  },
  isLink(editor: Editor, node: unknown | LinkInline): node is LinkInline {
    return Element.isElement(node) && editor.isInline(node) && node.type === 'link';
  },

  isCreateLinkDecoration(
    editor: Editor,
    leaf: unknown | CreateLinkDecoration
  ): leaf is CreateLinkDecoration {
    const l = leaf as CreateLinkDecoration;
    return Text.isText(l) && l.createLink === true;
  },

  isCreatingLink(editor: Editor, create?: boolean): boolean {
    return !!editor.creatingLink?.(create);
  },

  isLinkOnlySelected(editor: Editor): boolean {
    const [match] = Editor.nodes(editor, {
      match: node => LinkEditor.isLink(editor, node),
      universal: true
    });
    return !!match;
  },

  canCreateLink(editor: Editor): boolean {
    const {selection} = editor;
    if (!selection || !editor.creatingLink) return false;
    const anchorParentPath = Path.parent(selection.anchor.path);
    const focusParentPath = Path.parent(selection.focus.path);

    const canCreate =
      !LinkEditor.isCreatingLink(editor) &&
      Range.isExpanded(selection) &&
      anchorParentPath.length > 0 &&
      Path.equals(anchorParentPath, focusParentPath);

    if (!canCreate) return canCreate;

    const [containsLink] = Editor.nodes(editor, {
      match: node => LinkEditor.isLink(editor, node)
    });

    return !containsLink;
  },

  setCreatingLink(editor: Editor): boolean {
    const canCreate = LinkEditor.canCreateLink(editor);
    if (canCreate) {
      editor.creatingLink?.(true);
    }
    return canCreate;
  },

  unsetCreatingLink(editor: Editor): void {
    editor.creatingLink?.(false);
  },

  link(editor: Editor, href: string): void {
    if (LinkEditor.canCreateLink(editor)) {
      Transforms.wrapNodes(
        editor,
        {
          type: 'link',
          href,
          children: []
        },
        {
          mode: 'lowest',
          split: true
        }
      );
    }
    // move caret after link created
    Transforms.collapse(editor, {edge: 'end'});
    Transforms.move(editor, {unit: 'offset'});
  },

  unlink(editor: Editor) {
    Transforms.unwrapNodes(editor, {match: e => LinkEditor.isLink(editor, e)});
  },

  update(editor: Editor, link: LinkInline, props: SlateLinkData): void {
    Transforms.setNodes(editor, props, {
      match: e => link === e
    });
  },

  moveAfterLink(editor: Editor, link: LinkInline) {
    const {selection} = editor;
    if (selection) {
      const linkPath = ReactEditor.findPath(editor, link);
      if (linkPath) {
        Transforms.select(editor, Editor.end(editor, linkPath));
        Transforms.move(editor, {unit: 'offset'});
      }
    }
  }
};
