import {isSafari} from 'helpers/browser';

import {type SlatePlugin} from '../withPlugins';
import {type EditableProps} from '../../interface';
import {FormatEditor} from './FormatEditor';

/**
 * Handle native Safari Format
 * - macOS Selection->Context Menu->Format->Bold/Italic/Underline
 * - iOS Selection->Format->Bold/Italic/Underline
 */
export const withSafariFormat = (): SlatePlugin => editor => {
  if (!isSafari()) return editor;

  const editableProps: EditableProps = editor.editableProps || {};

  const {onDOMBeforeInput} = editableProps;

  editableProps.onDOMBeforeInput = e => {
    switch (e.inputType) {
      case 'formatBold':
        e.preventDefault();
        return FormatEditor.toggleMark(editor, 'bold');
      case 'formatItalic':
        e.preventDefault();
        return FormatEditor.toggleMark(editor, 'italic');
      case 'formatUnderline':
        e.preventDefault();
        return FormatEditor.toggleMark(editor, 'underline');
    }
    onDOMBeforeInput?.(e);
  };

  return editor;
};
