import {Editor, Element, Text} from 'slate';
import produce from 'immer';

import {type SlatePlugin} from '../../withPlugins';
import {normalizeText} from './text';
import {normalizeBlock} from './block';
import {normalizeEditor} from './editor';
import {addBlockDef, type BlockDef, type SlateDefRegistry} from '../../../definitions';
import {type SlateEditor} from '../SlateEditor';

/**
 * Core normalizer plugin
 */
export const withNormalizer: SlatePlugin = (editor: Editor & SlateEditor) => {
  let registry = produce({}, () => ({
    getBlockTypes() {
      return new Set();
    }
  })) as SlateDefRegistry;

  editor.addBlockDef = (...types: BlockDef[]) => {
    registry = addBlockDef(registry, ...types);
  };

  Object.defineProperty(editor, 'defRegistry', {
    get() {
      return registry;
    }
  });

  const {normalizeNode} = editor;

  editor.normalizeNode = (entry, options) => {
    const [node, path] = entry;

    if (Text.isText(node) && normalizeText(editor, [node, path], options)) return;
    if (
      Element.isElement(node) &&
      Editor.isBlock(editor, node) &&
      normalizeBlock(editor, [node, path], options)
    )
      return;
    if (Editor.isEditor(node) && normalizeEditor(editor, [node, path], options)) return;

    return normalizeNode(entry, options);
  };

  return editor;
};
