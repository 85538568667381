import {type ComponentProps, type FC} from 'react';
import {type BaseEditor, type Editor} from 'slate';

import {SlateContainer} from '../../../components/SlateContainer';
import {type SlatePlugin} from '../../withPlugins';

export type RenderContainerProps = Partial<ComponentProps<typeof SlateContainer>>;

export interface ContainerEditor extends BaseEditor {
  renderContainer: FC<RenderContainerProps>;
}

/**
 * Slate Container plugin.
 *
 * This is something like used to be a Wrapper Plugin in Slate 0.47
 * Implements editor.renderContainer - default container plugin for slate editor
 * Other plugins could override renderContainer (or even completely rewrite it) for some use cases
 * @param props
 */
export const withContainer =
  (props: RenderContainerProps = {}): SlatePlugin =>
  (editor: Editor & ContainerEditor) => {
    editor.renderContainer = ({children, ...rest}) => (
      <SlateContainer {...rest} {...props}>
        {children}
      </SlateContainer>
    );

    return editor;
  };
