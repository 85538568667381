import React, {type CSSProperties, type FC, memo} from 'react';
import {type Descendant, type Editor} from 'slate';
import {Editable, Slate} from 'slate-react';
import classNames from 'classnames';

import {isAndroid} from 'helpers/browser';

import {type SlatePlugin} from '../plugins';
import {useSlateEditor} from '../hooks/useSlateEditor';
import {useEditorChildren} from '../hooks/useEditorChildren';
import {useEditableProps} from '../hooks/useEditableProps';
import {type EditableProps} from '../interface';
import {type WidgetTypeComponentProps} from '../../../store/exercise/player/interface';
import styles from './SlateEditor.module.scss';

interface ToolbarOptions {
  hide?: boolean;
  portalId?: string;
}

type ValueProps =
  | {initialValue?: never; value?: never} // uncontrolled component, with default empty value
  | {initialValue?: Descendant[]; value?: never} // uncontrolled component, so the change of defaultValue does nothing
  | {initialValue?: never; value?: Descendant[]}; // controlled component, so the change of value re-initialize the editor with the new value (useful for external value updates)

type ControlledProps = ValueProps & {
  onChange?: (value: Descendant[], editor: Editor) => void;
  plugins?: SlatePlugin[];
};

export interface SlateEditorHandlers {
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => void;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>, editor: Editor) => void;
}

export type SlateEditorProps = React.PropsWithChildren<
  ControlledProps &
    Omit<EditableProps, keyof SlateEditorHandlers | keyof ControlledProps | 'autoFocus'> &
    SlateEditorHandlers & {
      className?: string;
      autoFocus?: 'start' | 'end' | boolean;
      style?: CSSProperties;
      readOnly?: boolean;
      placeholder?: string;
      normalizeOnMount?: boolean;
      skipSelectionChange?: boolean;
      toolbar?: ToolbarOptions;
      getWidgetProps?: () => WidgetTypeComponentProps;
    }
>;

export const SlateEditor: FC<SlateEditorProps> = memo(props => {
  const {key, editor, value, onChange} = useSlateEditor(props);

  const children = useEditorChildren(editor, props);
  const editableProps = useEditableProps(editor, props);

  const Container = editor.renderContainer;

  return (
    <Slate key={key} editor={editor} initialValue={value} onChange={onChange}>
      <Container className={classNames(styles.editor, 'slate-editor')}>
        <Editable
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          data-predictive-text={(isAndroid && 'disable') || undefined}
          {...editableProps}
        />
        {children}
      </Container>
    </Slate>
  );
});
