import React, {type FC, useCallback} from 'react';
import {useSlateSelector, useSlateStatic} from 'slate-react';
import {shallowEqual} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';

import {Button, type ToolbarButtonProps} from './Button';
import {ExampleEditor} from '../../plugins';
import {withEditor} from '../withEditor';
import styles from './ToggleExample.module.scss';

interface Props extends ToolbarButtonProps {}

export const ToggleExample: FC<Props> = withEditor(({tooltip, children, className, ...props}) => {
  const editor = useSlateStatic();

  const intl = useIntl();
  const shortcut = ExampleEditor.shortcut(editor);

  const [active, disabled] = useSlateSelector(editor => {
    const active = ExampleEditor.isActive(editor);
    const disabled = ExampleEditor.isDisabled();
    return [active, disabled];
  }, shallowEqual);

  const onClick = useCallback(() => {
    ExampleEditor.toggle(editor);
    tooltip?.()?.hide();
  }, [editor, tooltip]);

  return (
    <Button
      active={active}
      disabled={disabled}
      shortcut={shortcut}
      title={intl.formatMessage({id: 'Common.Example'})}
      className={classNames(className, styles.btn, 'labeled')}
      onClick={onClick}
      {...props}
    >
      <FormattedMessage id="Slate.Toolbar.Button.ToggleExample.Label" />
    </Button>
  );
}, ExampleEditor.is);
