import React from 'react';
import {type Editor, Node} from 'slate';
import {type RenderLeafProps} from 'slate-react';

import {isShortcut, type Shortcut} from 'helpers/shortcut';

import {type SlatePlugin, withPlugins} from '../withPlugins';
import {type EditableProps, type FormatMarks} from '../../interface';
import {FormatLeaf} from './FormatLeaf';
import {mergeAttrs} from './formatUtils';
import {SlateLeaf} from '../../components/SlateLeaf';
import {type FormatAttrs, type MarkDef, type MarkType} from '../../definitions';
import {withSafariFormat} from './withSafariFormat';
import {type FormatDescription, type MarkFormats} from './interface';
import {FormatEditor} from './FormatEditor';

export const withFormat =
  (formats: MarkDef[] = []): SlatePlugin =>
  (e: Editor): Editor => {
    const editor = e as Editor & FormatEditor;
    const {editableProps = {} as EditableProps} = editor;

    const editorFormats: MarkFormats = editor.getFormats?.() || {};
    const markShortcuts: {[m in MarkType]?: Shortcut} = {};

    formats.forEach(f => {
      const formatValues = editorFormats[f.mark] || [];
      formatValues.push({value: f.value, attrs: f.attrs});
      editorFormats[f.mark] = formatValues;
      markShortcuts[f.mark] = f.shortcut;
    });

    editor.getFormats = () => editorFormats;
    editor.getFormatShortcut = (mark: MarkType) => markShortcuts[mark];

    editor.getFormatMarkAttrs = (formatMarks: FormatMarks) => {
      const formats: MarkFormats = editor.getFormats?.() || {};
      return Object.entries(formatMarks).reduce(
        (attrs: FormatAttrs, [mark, value]: [MarkType, unknown]) => {
          const formatValues =
            formats[mark]?.filter((fv: FormatDescription) => fv.value === value) || [];

          return mergeAttrs(
            attrs,
            formatValues.reduce(
              (a: FormatAttrs, fv: FormatDescription) => mergeAttrs(a, fv.attrs),
              {}
            ) || {}
          );
        },
        {}
      );
    };

    editor.hasFormatMarks = (formatMarks: FormatMarks) => {
      const formats: MarkFormats = editor.getFormats?.() || {};

      return Object.entries(formatMarks).some(([mark, value]) =>
        formats[mark]?.some((v: FormatDescription) => v.value === value)
      );
    };

    const onKeyDown = editableProps.onKeyDown;

    editableProps.onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      Object.entries(markShortcuts).forEach(([m, s]: [MarkType, Shortcut]) => {
        if (!isShortcut(e, s)) return;

        e.preventDefault();
        FormatEditor.toggleMark(editor, m);
      });
      onKeyDown?.(e);
    };

    const {renderLeaf: Leaf = SlateLeaf} = editableProps;

    editableProps.renderLeaf = (props: RenderLeafProps) => {
      const leafMarks = Node.extractProps(props.leaf);
      const lm = leafMarks as FormatMarks;

      if (!editor.hasFormatMarks?.(lm)) {
        return <Leaf {...props}>{props.children}</Leaf>;
      }

      return <FormatLeaf {...props}>{props.children}</FormatLeaf>;
    };

    return withPlugins(editor, withSafariFormat());
  };
