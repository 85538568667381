import {Editor, Path, Transforms} from 'slate';

import {type NormalizeEntryFn} from '../core';
import {type ListItemElement} from '../../interface';
import {ListEditor} from './ListEditor';

export const normalizeListItem: NormalizeEntryFn<ListItemElement> = (editor, [listItem, path]) => {
  const size = listItem.children.length;
  const lastChild = listItem.children[size - 1];

  const nestedListIndex = listItem.children.findIndex(n => ListEditor.isList(n));
  const notListAfterNestedListIndex = listItem.children.findIndex(
    (n, i) => i > nestedListIndex && Editor.isBlock(editor, n) && !ListEditor.isList(n)
  );

  const hasNestedListButNotLast = nestedListIndex > 0 && nestedListIndex !== size - 1;

  const nestedListAfterNestedListIndex = hasNestedListButNotLast
    ? listItem.children.findIndex((n, i) => i > nestedListIndex && ListEditor.isList(n))
    : -1;

  const hasMoreThanOneNestedList = nestedListAfterNestedListIndex > 0;
  const nestedListPath = [...path, nestedListIndex];
  const nestedListAfterNestedListPath = [...path, nestedListAfterNestedListIndex];

  const hasNestedSiblingLists =
    hasMoreThanOneNestedList && Path.isSibling(nestedListPath, nestedListAfterNestedListPath);
  const hasElementAfterNestedList = hasNestedListButNotLast && notListAfterNestedListIndex > 0;
  const firstChildIsNestedList = nestedListIndex === 0;

  if (!size) {
    // ListItem should not be empty
    Transforms.removeNodes(editor, {at: path});
    return true;
  }

  if (hasElementAfterNestedList) {
    // NestedList if exists in ListItem should be the last child
    ListEditor.moveParagraphAfterEmbeddedListAsLastBlockOfEmbeddedList(editor, [
      lastChild,
      [...path, notListAfterNestedListIndex]
    ]);
  } else if (hasMoreThanOneNestedList && hasNestedSiblingLists) {
    // There should be the only one NestedList inside ListItem
    Transforms.mergeNodes(editor, {at: nestedListAfterNestedListPath});
  } else if (firstChildIsNestedList) {
    // NestedList should not be a first element in ListItem
    Transforms.insertNodes(editor, {children: []}, {at: nestedListPath});
  }

  // ListItem should not live in Editor or outside parent List element
  const [parent] = Editor.parent(editor, path);
  if (parent && (Editor.isEditor(parent) || !ListEditor.isList(parent))) {
    Transforms.unwrapNodes(editor, {at: path, mode: 'lowest'});
    return true;
  }

  return;
};
