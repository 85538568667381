import {type KeyboardEvent} from 'react';
import {Editor, Element, type NodeEntry, Path, Range, Transforms} from 'slate';
import {DefaultElement, ReactEditor} from 'slate-react';

import {type EditableProps} from 'components/SlateJS/interface';
import isShortcut from 'helpers/shortcut';

import {type SlatePlugin} from '../withPlugins';
import {type LinkElementProps, type Target} from './interface';
import {LinkElement as Link} from './LinkElement';
import {normalizeLink} from './normalizeLink';
import {CreateLink} from './CreateLink';
import {SlateHotkey} from '../../utils/hotkeys';
import {SlateLeaf} from '../../components/SlateLeaf';
import {LinkEditor} from './LinkEditor';

interface Options {
  target?: Target;
  className?: string;
}

export const withLink =
  ({target = '_blank', className}: Options = {}): SlatePlugin =>
  editor => {
    let isCreatingLink = false;

    editor.creatingLink = (create?: boolean): boolean => {
      if (create !== undefined && isCreatingLink !== create) {
        isCreatingLink = create;
        editor.onChange();
      }
      return isCreatingLink;
    };
    const {isInline, normalizeNode, editableProps = {} as EditableProps} = editor;

    const {
      decorate,
      onKeyDown,
      renderElement: Node = DefaultElement,
      renderLeaf: Leaf = SlateLeaf
    } = editableProps;

    editor.isInline = element => {
      return element.type === 'link' || isInline(element);
    };

    editor.normalizeNode = (entry, options) => {
      const [node, path] = entry;
      if (LinkEditor.isLink(editor, node) && normalizeLink(editor, [node, path], options)) return;
      normalizeNode(entry, options);
    };

    editableProps.decorate = ([node, path]: NodeEntry) => {
      const isCreatingLink = editor.creatingLink?.();
      const decorations: Range[] = decorate?.([node, path]) || [];
      const {selection} = editor;

      if (selection && isCreatingLink && Element.isElement(node) && Editor.isBlock(editor, node)) {
        const intersection = Range.intersection(selection, Editor.range(editor, path));

        if (intersection) {
          const range: Range = {
            createLink: true,
            ...intersection
          };
          decorations.unshift(range);
        }
      }
      return decorations;
    };

    editableProps.onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      const {selection} = editor;

      if (isShortcut(event, SlateHotkey.Link)) {
        if (LinkEditor.setCreatingLink(editor)) {
          ReactEditor.blur(editor);
        } else if (LinkEditor.isLinkOnlySelected(editor)) {
          LinkEditor.unlink(editor);
        }
      }

      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      if (selection && Range.isCollapsed(selection) && LinkEditor.isLinkOnlySelected(editor)) {
        if (isShortcut(event, SlateHotkey.Left)) {
          event.preventDefault();
          Transforms.move(editor, {unit: 'offset', reverse: true});
          return;
        }
        if (isShortcut(event, SlateHotkey.Right)) {
          event.preventDefault();
          Transforms.move(editor, {unit: 'offset'});
          return;
        }
      }
      return onKeyDown?.(event);
    };
    editableProps.renderLeaf = props => {
      const {leaf, text} = props;
      const {selection} = editor;

      if (!selection || !LinkEditor.isCreateLinkDecoration(editor, leaf))
        return <Leaf {...props}>{props.children}</Leaf>;

      const path = ReactEditor.findPath(editor, text);
      const leafElement = (
        <Leaf {...props}>
          <span className="slate-create-link">{props.children}</span>
        </Leaf>
      );

      return Path.equals(Range.start(selection).path, path) ? (
        <CreateLink>{leafElement}</CreateLink>
      ) : (
        leafElement
      );
    };
    editableProps.renderElement = (props: LinkElementProps) => {
      return LinkEditor.isLink(editor, props.element) ? (
        <Link {...props} target={target} className={className} href={props.element.href}>
          {props.children}
        </Link>
      ) : (
        <Node {...props}>{props.children}</Node>
      );
    };

    return editor;
  };
