import classNames from 'classnames';
import {type CSSProperties} from 'react';

import {type FormatAttrs} from '../../definitions';

const concatProperties: (keyof CSSProperties)[] = ['textDecorationLine'];

export const mergeAttrs = (a: Readonly<FormatAttrs>, b?: Readonly<FormatAttrs>): FormatAttrs => {
  const attrs: FormatAttrs = {...a} || {};
  attrs.className = classNames(attrs.className, b?.className) || undefined;
  attrs.style = mergeStyle(attrs.style, b?.style);
  return attrs;
};

export const mergeStyle = (a: CSSProperties = {}, b: CSSProperties = {}): CSSProperties =>
  Object.keys(b).reduce(
    (result: CSSProperties, k) => {
      a[k] = concatProperties.includes(k as keyof CSSProperties)
        ? [a[k] || '', b[k]].join(' ').trim()
        : b[k];
      return a;
    },
    {...a}
  );

export const intersectProps = <T extends object>(o1: T, o2: T): T => {
  return Object.keys(o1)
    .filter(k => k in o2 && o1[k] === o2[k])
    .reduce((result, key) => {
      result[key] = o1[key];
      return result;
    }, {} as T);
};
