import {Editor, Transforms} from 'slate';
import type React from 'react';
import {ReactEditor} from 'slate-react';

import {type SlatePlugin} from './withPlugins';
import {type EditablePropsEditor} from './core/react/withEditableProps';
import {SlateEditor} from './core';

export const withAutoFocus: SlatePlugin = (editor: Editor & EditablePropsEditor) => {
  const editableProps = editor.editableProps || {};
  const {onFocus} = editableProps;

  editableProps.onFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    const edge = SlateEditor.autoFocus(editor);
    const isReadOnly = ReactEditor.isReadOnly(editor);
    const query = edge === 'end' ? Editor.end : Editor.start;
    if (!isReadOnly && edge && editor.selection === null) {
      Transforms.select(editor, query(editor, []));
    }
    onFocus?.(e);
  };

  return editor;
};
