import {ReactEditor, type RenderElementProps, useSlateSelector} from 'slate-react';
import {type FC} from 'react';
import {Editor, type Path} from 'slate';
import classNames from 'classnames';

import {type ListItemElement as ListItemElementType} from '../../interface';
import {FormatEditor} from '../format';
import {type FormatAttrs} from '../../definitions';

import './ListItemElement.scss';

export interface RenderListItemProps extends RenderElementProps, FormatAttrs {
  element: ListItemElementType;
}
export const ListItemElement: FC<RenderListItemProps> = ({
  className = 'sb-li',
  children,
  attributes,
  element
}) => {
  const format = useSlateSelector(editor => {
    let path: Path | null = [...ReactEditor.findPath(editor, element), 0]; // first child node path
    if (!Editor.hasPath(editor, path)) {
      path = null;
    }

    const at = path && Editor.range(editor, ...Editor.edges(editor, path)); // edges of first child node of list item

    const formatMarks = at && FormatEditor.activeMarks(editor, {at});
    return formatMarks && FormatEditor.formatMarkAttrs(editor, formatMarks)?.className;
  });

  return (
    <li {...attributes} className={classNames(className, format)}>
      {children}
    </li>
  );
};
