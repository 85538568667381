import {Editor, Transforms, Node} from 'slate';

import {type NormalizeEntryFn} from '../core';
import {type ExampleElement} from '../../interface';

export const normalizeExample: NormalizeEntryFn<ExampleElement> = (editor, [node, path]) => {
  const [parent] = Editor.parent(editor, path);
  if (!Editor.isEditor(parent)) {
    // example element could live only inside editor node (the highest level block)
    Transforms.unwrapNodes(editor, {at: path, match: n => n === node});
    return true;
  }
  if (Editor.hasInlines(editor, node)) {
    // example element is a wrapper for other blocks, so it should not contain texts/inlines
    Transforms.unsetNodes(editor, 'type', {at: path, match: n => n === node});
  }
  const props = Node.extractProps(node);
  const propKeys = Object.keys(props).filter(p => p !== 'type');
  if (propKeys.length) {
    // example element should not contain any unknown props
    Transforms.unsetNodes(editor, propKeys, {at: path, match: n => n === node});
  }
  return;
};
