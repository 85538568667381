import React, {type FC, useCallback, useRef} from 'react';
import {ReactEditor, useSlateStatic} from 'slate-react';

import LinkTooltip from 'components/Slate/SlateEditor/plugins/button/Link/LinkTooltip';

import {LinkEditor} from './LinkEditor';

interface Props {}
export const CreateLink: FC<Props> = props => {
  const {children} = props;
  const editor = useSlateStatic();
  const ref = useRef<HTMLSpanElement | null>(null);
  const reset = useCallback(
    (focus?: true) => {
      LinkEditor.unsetCreatingLink(editor);
      focus && ReactEditor.focus(editor);
    },
    [editor]
  );

  const save = useCallback(
    ({href}: {href: string}) => {
      LinkEditor.unsetCreatingLink(editor);
      LinkEditor.link(editor, href);
      ReactEditor.focus(editor);
    },
    [editor]
  );

  const getTooltipContainer = useCallback(() => ref.current!, []);
  return (
    <span onClick={e => e.stopPropagation()} ref={ref} style={{userSelect: 'none'}}>
      <LinkTooltip
        reset={reset}
        save={save}
        getTooltipContainer={getTooltipContainer}
        contentEditable={false}
      >
        <span>{children}</span>
      </LinkTooltip>
    </span>
  );
};
