import React, {type ComponentProps, type FC} from 'react';

import {Toolbar} from './Toolbar';
import {Redo, Undo} from './History';
import {Separator} from './Separator';
import {Bold, Italic, StrikeThrough, Underline} from './Format';
import {FontSizeSelector} from './FontSizeSelector';
import {FontColorSelector} from './FontColorSelector';
import {HighlightSelector} from './HighlightSelector';
import {ToggleLink} from './ToggleLink';
import {CharSelector} from './CharSelector';
import {DebugButton} from './DebugButton';
import {OrderedListSelector} from './OrderedListSelector';
import {UnorderedList} from './UnorderedList';
import {ToggleExample} from './ToggleExample';

export const DefaultToolbar: FC<ComponentProps<typeof Toolbar>> = ({
  inPortal = false,
  ...props
}) => {
  return (
    <Toolbar inPortal={inPortal} {...props}>
      <Undo />
      <Redo />

      <Separator />

      <Bold />
      <Italic />
      <Underline />
      <StrikeThrough />

      <Separator />

      <FontSizeSelector />
      <FontColorSelector />
      <HighlightSelector />

      <Separator />

      <OrderedListSelector />
      <UnorderedList />

      <Separator />

      <ToggleLink />
      <CharSelector />

      <Separator />

      <ToggleExample />

      <DebugButton />
    </Toolbar>
  );
};
