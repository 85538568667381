import {type KeyboardEvent} from 'react';
import {DefaultElement, type RenderPlaceholderProps} from 'slate-react';
import {Editor} from 'slate';

import {type SlatePlugin, withPlugins} from '../withPlugins';
import {withListItem} from './withListItem';
import {normalizeList} from './normalizeList';
import isShortcut from '../../../../helpers/shortcut';
import {SlateHotkey} from '../../utils';
import {ListEditor} from './ListEditor';
import {type SlateListData} from '../../interface';
import {nestedLists} from './lists';
import {ListElement, type RenderListProps} from './ListElement';
import {SlatePlaceholder} from '../../components/SlatePlaceholder';
import {type BlockDef} from '../../definitions';
import {SlateEditor} from '../core';

export interface ListBlockDef extends BlockDef {
  block: 'list';
  props: SlateListData;
}

interface Options {
  blocks?: ListBlockDef[];
  className?: string;
}

export const withList =
  ({blocks = nestedLists, className}: Options = {}): SlatePlugin =>
  (editor: Editor) => {
    const editableProps = editor.editableProps || {};
    const {
      onKeyDown,
      renderElement: Element = DefaultElement,
      renderPlaceholder: Placeholder = SlatePlaceholder
    } = editableProps;
    const {normalizeNode} = editor;

    SlateEditor.defineBlock(editor, ...blocks);

    editor.normalizeNode = ([node, path], options) => {
      if (ListEditor.isList(node) && normalizeList(editor, [node, path], options)) return;

      normalizeNode([node, path], options);
    };

    editableProps.onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      for (const block of blocks) {
        const {
          props: {list: listType, listStyleType = undefined},
          shortcut
        } = block;
        if (isShortcut(e, shortcut)) {
          e.preventDefault();
          ListEditor.toggleList(editor, listType, listStyleType);
          return;
        }
      }

      if (
        (isShortcut(e, SlateHotkey.SoftBreak) && ListEditor.softEnter(editor)) ||
        (isShortcut(e, SlateHotkey.Enter) && ListEditor.enter(editor)) ||
        (isShortcut(e, SlateHotkey.Delete) && ListEditor.delete(editor)) ||
        (isShortcut(e, SlateHotkey.Backspace) && ListEditor.backspace(editor))
      ) {
        e.preventDefault();
        return;
      }

      return onKeyDown?.(e);
    };

    editableProps.renderElement = (props: RenderListProps) => {
      if (ListEditor.isList(props.element)) {
        return (
          <ListElement {...props} className={className}>
            {props.children}
          </ListElement>
        );
      }
      return <Element {...props}>{props.children}</Element>;
    };

    editableProps.renderPlaceholder = ({children, ...props}: RenderPlaceholderProps) => {
      // do not render placeholder in the empty editor with the only empty list node inside
      const path = [0];
      if (Editor.hasPath(editor, path)) {
        const [firstNode] = Editor.node(editor, path);
        if (ListEditor.isList(firstNode)) {
          return <>{null}</>;
        }
      }

      return <Placeholder {...props}>{children}</Placeholder>;
    };

    return withPlugins(editor, withListItem());
  };
