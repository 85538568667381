import {Editor, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';

import {type EditableProps} from 'components/SlateJS/interface';

import {InlineInputPlaceholder} from '../components/InlineInput/InlineInputPlaceholder';
import {SlateEditor} from './core/SlateEditor';
/**
 * InlineInput plugin
 *
 * Allows only one block node in top level to make editor behave like input
 * @param editor
 */
export const withInlineInput = <P extends Editor>(editor: P): P => {
  const normalizeNode = editor.normalizeNode;
  editor.normalizeNode = (entry, options) => {
    const [node] = entry;

    if (Editor.isEditor(node) && node.children.length > 1) {
      Transforms.mergeNodes(editor, {at: [1]});

      return;
    }
    normalizeNode(entry, options);
  };

  const editableProps = editor.editableProps || ({} as EditableProps);
  const {onFocus, onBlur, onSelect} = editableProps;
  editableProps.renderPlaceholder = ({children, ...props}) => (
    <InlineInputPlaceholder {...props}>{children}</InlineInputPlaceholder>
  );
  editableProps.style = {
    ...editableProps.style,
    position: 'initial' // fixes invisible caret in Slate InlineInput
  };

  editableProps.onSelect = e => {
    onSelect?.(e);
    // Sometimes editor is focused, but DOM selection is at the first next text node after the editor.
    // If so, try to select at the end of the editor
    if (!SlateEditor.isDOMSelectionInEditor(editor) && Editor.string(editor, []).length) {
      ReactEditor.deselect(editor);
      setTimeout(() => {
        Transforms.select(editor, Editor.end(editor, []));
      });
    }
  };

  editableProps.onFocus = e => {
    onFocus?.(e);
    // Sometimes editor is focused, but DOM selection is at the first next text node after the editor.
    // If so, try to select at the end of the editor
    setTimeout(() => {
      if (!SlateEditor.isDOMSelectionInEditor(editor)) {
        ReactEditor.deselect(editor);
        setTimeout(() => {
          Transforms.select(editor, Editor.end(editor, []));
        });
      }
    });
  };

  editableProps.onBlur = e => {
    onBlur?.(e);
    ReactEditor.deselect(editor);
  };

  return editor;
};
