import {type BaseEditor, Editor, type Location, Node, Range, Text} from 'slate';

import {type ActiveMarkOptions, type FormatDescription, type MarkFormats} from './interface';
import {type FormatAttrs, type MarkType} from '../../definitions';
import {type Shortcut} from '../../../../helpers/shortcut';
import {type FormatMarks} from '../../interface';
import {SlateEditor} from '../core/SlateEditor';
import {intersectProps} from './formatUtils';

export interface FormatEditor extends BaseEditor {
  getFormats?: () => MarkFormats;
  getFormatShortcut?: (mark: MarkType) => Shortcut | undefined;
  getFormatMarkAttrs?: (formatMarks: FormatMarks) => FormatAttrs;
  hasFormatMarks?: (formatMarks: FormatMarks) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormatEditor = {
  /**
   * Get the marks that would be added to text at the current selection.
   * Use this query instead of Editor.marks()
   */
  marks(editor: Editor): FormatMarks | null {
    if (!editor.marks && editor.selection && SlateEditor.isPreviousPointInMarkableVoid(editor)) {
      const [node] = Editor.leaf(editor, editor.selection.anchor.path);
      return Node.extractProps(node) as FormatMarks;
    }

    return Editor.marks(editor);
  },
  isActiveMark(
    editor: Editor,
    mark: MarkType,
    {at: location, value, universal = true}: ActiveMarkOptions = {}
  ): boolean {
    const at: Location | null = location || editor.selection;
    if (!at) return false;
    const shouldCheckValue = value !== undefined;

    // for collapsed selection we check if mark is set in editor
    if (
      editor.selection &&
      Range.isRange(at) &&
      Range.isCollapsed(at) &&
      Range.equals(at, editor.selection)
    ) {
      const editorMarks = FormatEditor.marks(editor);

      return (
        !!editorMarks &&
        Object.keys(editorMarks).includes(mark) &&
        (!shouldCheckValue || editorMarks[mark] === value)
      );
    }

    // for expanded selection we check whether every continuous nodes at the location (universal=true) have mark
    const [match] = Editor.nodes(editor, {
      at,
      match: n => !!n[mark] && (!shouldCheckValue || n[mark] === value),
      universal,
      voids: true
    });

    return !!match;
  },
  isInactiveMark(editor: Editor, mark: MarkType): boolean {
    return !FormatEditor.isActiveMark(editor, mark, {universal: false});
  },
  toggleMark(editor: Editor, mark: MarkType, value: unknown = true): void {
    if (!FormatEditor.isActiveMark(editor, mark, {value})) {
      editor.addMark(mark, value);
    } else {
      editor.removeMark(mark);
    }
  },
  hasFormatMark(editor: Editor, mark: MarkType, value: unknown = true): boolean {
    return !!editor.hasFormatMarks?.({[mark]: value});
  },
  filterProps(editor: Editor, node: Text): string[] {
    const formats = editor.getFormats?.() || {};

    const allowedValues = Object.keys(formats).reduce<{
      [key in MarkType]?: FormatDescription['value'][];
    }>((result, mark) => {
      result[mark] = (formats[mark] || []).map((mv: FormatDescription) => mv.value);
      return result;
    }, {});

    const marks = Node.extractProps(node);

    return Object.entries(marks).reduce<string[]>((invalidProps, [mark, value]) => {
      !allowedValues[mark]?.includes(value) && invalidProps.push(mark);
      return invalidProps;
    }, []);
  },
  activeMarks(editor: Editor, {at: location}: {at?: Location} = {}): FormatMarks | null {
    const at: Location | null = location || editor.selection || null;
    if (!at) return null;
    const nodes = Editor.nodes(editor, {
      at,
      match: Text.isText,
      mode: 'lowest',
      universal: true,
      voids: true
    });
    // as default active marks we use FormatEditor.marks() result for current collapsed selection
    const activeFormatMarks: FormatMarks =
      editor.selection &&
      Range.isCollapsed(editor.selection) &&
      Range.isRange(at) &&
      Range.equals(at, editor.selection)
        ? FormatEditor.marks(editor) || {}
        : {};
    let marksIntersection: FormatMarks | null = null;
    for (const [n] of nodes) {
      const props = Node.extractProps(n) as FormatMarks;
      if (!marksIntersection) {
        marksIntersection = props;
      } else {
        marksIntersection = intersectProps(props, marksIntersection);
      }
    }
    if (!marksIntersection) return null;
    Object.entries(marksIntersection).forEach(([mark, value]) => {
      const isActive = FormatEditor.isActiveMark(editor, mark as MarkType, {at, value});
      if (isActive && !activeFormatMarks[mark]) {
        activeFormatMarks[mark] = value;
      }
    });
    return Object.keys(activeFormatMarks).length ? activeFormatMarks : null;
  },
  formatMarkAttrs(editor: FormatEditor, marks: FormatMarks): FormatAttrs {
    return editor.getFormatMarkAttrs?.(marks) || {};
  },
  formats(editor: Editor): MarkFormats {
    return editor.getFormats?.() || {};
  },
  shortcut(editor: Editor, mark: MarkType): Shortcut | undefined {
    return editor.getFormatShortcut?.(mark);
  }
};
