import React, {useCallback} from 'react';
import {useSlateStatic} from 'slate-react';

import Icon from 'components/Icon';

import {Button} from './Button';
import {withEditor} from '../withEditor';
import {SlateEditor} from '../../plugins';

export const DebugButton = withEditor(
  () => {
    const editor = useSlateStatic();
    return (
      <Button
        onMouseDown={useCallback(
          e => {
            e.preventDefault();
            e.stopPropagation();
            /* eslint-disable no-console */
            console.groupCollapsed(`Slate Debug id=${SlateEditor.id(editor)}`);
            console.log('Editor:', editor);
            console.log('Children:', editor.children);
            console.log('Selection:', editor.selection);

            console.groupEnd();
            /* eslint-enable no-console */
          },
          [editor]
        )}
      >
        <Icon name="bug" />
      </Button>
    );
  },
  () => import.meta.env.MODE === 'development'
);
