import React, {type FC, useCallback} from 'react';
import {useSlateSelector, useSlateStatic} from 'slate-react';
import {shallowEqual} from 'react-redux';

import {Button, type ToolbarButtonProps} from './Button';
import {type ListStyleType, type ListType} from '../../../Slate/interface';
import {ListEditor} from '../../plugins';

interface Props extends ToolbarButtonProps {
  type: ListType;
  listStyleType?: ListStyleType;
}

export const ToggleList: FC<Props> = ({type, listStyleType, tooltip, children, ...props}) => {
  const editor = useSlateStatic();

  const [active, disabled] = useSlateSelector(editor => {
    const active = ListEditor.isActive(editor, type, listStyleType);
    const disabled = ListEditor.isDisabled(editor, type, listStyleType);
    return [active, disabled];
  }, shallowEqual);

  const onClick = useCallback(() => {
    ListEditor.toggleList(editor, type, listStyleType);
    tooltip?.()?.hide();
  }, [editor, listStyleType, tooltip, type]);

  return (
    <Button active={active} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};
