import {type Editor} from 'slate';

import {type SlateEditorProps} from '../components/SlateEditor';
import {type SlateEditor} from '../plugins';
import {type Mutable} from '../interface';

export const useEditorQueries = (
  editor: Mutable<Editor & SlateEditor>,
  props: SlateEditorProps
) => {
  editor.getEditorProps = () => props;
};
