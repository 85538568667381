import {type Editor} from 'slate';
import {DefaultElement} from 'slate-react';

import {type SlatePlugin} from '../withPlugins';
import {type EditablePropsEditor, SlateEditor} from '../core';
import {ListItemElement, type RenderListItemProps} from './ListItemElement';
import {normalizeListItem} from './normalizeListItem';
import {ListEditor} from './ListEditor';

interface Options {
  className?: string;
}

export const withListItem =
  ({className}: Options = {}): SlatePlugin =>
  (editor: Editor & EditablePropsEditor) => {
    const editableProps = editor.editableProps || {};
    const {normalizeNode} = editor;
    const {renderElement: Default = DefaultElement} = editableProps; // TODO: review default element

    SlateEditor.defineBlock(editor, {block: 'listItem'});

    editor.normalizeNode = ([node, path], options) => {
      if (ListEditor.isListItem(node) && normalizeListItem(editor, [node, path], options)) return;

      normalizeNode([node, path], options);
    };

    editableProps.renderElement = (props: RenderListItemProps) => {
      if (ListEditor.isListItem(props.element)) {
        return (
          <ListItemElement {...props} className={className}>
            {props.children}
          </ListItemElement>
        );
      }
      return <Default {...props}>{props.children}</Default>;
    };

    return editor;
  };
