import {createEditor, type Descendant, type Editor} from 'slate';
import {useCallback, useLayoutEffect, useMemo, useReducer, useRef} from 'react';
import {withReact} from 'slate-react';

import {genKey} from 'components/Slate/utils';

import {valueFromText} from '../utils';
import {
  type SlatePlugin,
  withAutoFocus,
  withContainer,
  withEditableProps,
  withNormalizer,
  withPlugins,
  withSlateEditor
} from '../plugins';
import {type SlateEditorProps} from '../components/SlateEditor';
import {useEditorQueries} from './useEditorQueries';
import {useEditorAutoNormalize} from './useEditorAutoNormalize';

interface Result {
  key: string | number;
  value: Descendant[];
  editor: Editor;
  onChange: (value: Descendant[]) => void;
}

const corePlugins: SlatePlugin[] = [
  withSlateEditor,
  withReact,
  withContainer(),
  withEditableProps,
  withAutoFocus,
  withNormalizer
];

const defaultValue: Descendant[] = valueFromText();

export const useSlateEditor = (props: SlateEditorProps): Result => {
  const {
    initialValue,
    plugins: pluginsProp,
    value: valueProp,
    onChange: onChangeProp,
    skipSelectionChange = false
  } = props;

  const initialValueRef = useRef<Descendant[] | undefined>(initialValue);
  const currentValue = valueProp || initialValueRef.current || defaultValue;
  const valueRef = useRef<Descendant[] | undefined>(currentValue);

  const [epoch, setEpoch] = useReducer((s: number) => s + 1, 0);

  const onLocalChange = useCallback((value: Descendant[]) => {
    valueRef.current = value;
  }, []);

  const [editor, key]: [Editor, string, number] = useMemo(
    () => [withPlugins(createEditor(), ...corePlugins, ...(pluginsProp || [])), genKey(), epoch],
    [epoch, pluginsProp]
  );

  const onChange = useCallback(
    (value: Descendant[]) => {
      onLocalChange(value);

      if (
        !skipSelectionChange ||
        !editor.operations.every(
          op => 'set_selection' === op.type || 'react_request_rerender' === op.type
        )
      ) {
        onChangeProp?.(value, editor);
      }
    },
    [editor, onChangeProp, onLocalChange, skipSelectionChange]
  );

  const isExternalUpdateRender = valueRef.current !== currentValue;
  const value = (isExternalUpdateRender && valueRef.current) || currentValue;

  useLayoutEffect(() => {
    if (valueRef.current !== currentValue) {
      valueRef.current = currentValue;
      setEpoch();
    }
  }, [currentValue]);

  useEditorQueries(editor, props);
  useEditorAutoNormalize(editor, props);

  return {
    editor,
    key,
    value,
    onChange
  };
};
