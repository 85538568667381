import {ReactEditor, type RenderElementProps, useSlateSelector} from 'slate-react';
import {type FC} from 'react';
import classNames from 'classnames';
import {Path} from 'slate';
import {shallowEqual} from 'react-redux';

import {type ListElement as ElementType} from '../../interface';
import {ListEditor} from './ListEditor';
import './ListElement.scss';
import {type FormatAttrs} from '../../definitions';

export interface RenderListProps extends RenderElementProps, FormatAttrs {
  element: ElementType;
}
export const ListElement: FC<RenderListProps> = ({
  children,
  attributes,
  className = 'sb-l',
  style,
  ...props
}) => {
  const {list, listStyleType} = props.element;

  const [absoluteDepth, relativeDepth] = useSlateSelector(editor => {
    const path = ReactEditor.findPath(editor, props.element);
    // get the furthest subsequent list of the same type for determine relative list depth
    const furthestList = ListEditor.getFurthestSubsequentListOfType(
      editor,
      [props.element, path],
      props.element.list,
      listStyleType
    );
    const relativeDepth = furthestList ? Path.relative(path, furthestList[1]).length / 2 : 0;

    // get root list to determine absolute list depth
    const rootList = ListEditor.highestAbove(editor, [props.element, path]);
    const absoluteDepth = rootList ? Path.relative(path, rootList[1]).length / 2 : 0;
    return [absoluteDepth, relativeDepth];
  }, shallowEqual);

  const Element = list;
  return (
    <Element
      {...attributes}
      className={classNames(
        className,
        `${className}-${list}`,
        `${className}-${list}-${absoluteDepth}-${relativeDepth}`,
        listStyleType
      )}
      style={style}
    >
      {children}
    </Element>
  );
};
