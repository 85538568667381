import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {type SelectionData} from 'store/interface';

import {clearPointerSelection} from '../../../common/action';
import {PointerElementTooltip} from './PointerElementTooltip';
import {type PointerListenerRef} from './PointerElementListener';

const defaultTrigger = ['click'];

const animationClass = 'pointer-listener--animated';

interface Props {
  ref?: PointerListenerRef;
  trigger?: string[];
  align?: TooltipProps['align'];
  placement?: string;
  isNotTeacher: boolean;
  children: React.ReactElement;
  selectionData?: SelectionData;
  mouseLeaveDelay?: number;
  renderTooltipOverlay?: () => React.ReactElement;
  getTooltipContainer?: () => HTMLElement;
  tooltipClassName?: string;
  overlayClassName?: string;
  relatedElement?: string;
  passive?: boolean;
  inline?: boolean;
  onSelected?: (callback: () => void) => void;
}

export const PointerElement: React.FC<Props> = React.memo(
  React.forwardRef(
    (
      {
        children,
        trigger,
        align,
        isNotTeacher,
        selectionData,
        tooltipClassName,
        overlayClassName,
        mouseLeaveDelay,
        renderTooltipOverlay,
        getTooltipContainer,
        relatedElement,
        passive,
        inline,
        placement,
        onSelected
      }: Props,
      ref: PointerListenerRef
    ) => {
      const dispatch = useDispatch();

      const [visible, setVisible] = useState(false);

      const tooltipTrigger = trigger || defaultTrigger;
      const hasHoverTrigger = tooltipTrigger.includes('hover');
      const hasClickTrigger = tooltipTrigger.includes('click');

      const getContainer = useCallback(
        () => document.getElementById(children.props.id) as HTMLElement,
        [children]
      );

      const onMouseLeaveHandler = useCallback(
        (onMouseLeave?: Function) => (event: MouseEvent) => {
          onMouseLeave?.(event);

          const relatedTarget = event.relatedTarget as Element;

          if (!mouseLeaveDelay && relatedTarget.tagName?.toLowerCase() !== 'button') {
            setVisible(false);
          }
        },
        [mouseLeaveDelay]
      );
      const onAnimationStartHandler = useCallback(
        (onAnimationStart?: Function) => (event: React.SyntheticEvent) => {
          onAnimationStart?.(event);
          setVisible(false);
        },
        []
      );

      const onAnimationEndHandler = useCallback(
        (onAnimationEnd?: Function) => (event: React.SyntheticEvent) => {
          onAnimationEnd?.(event);

          const container = getContainer();

          if (container) {
            container.classList.remove(animationClass);
          }
        },
        [getContainer]
      );

      const onVisibleChange = useCallback(
        (isVisible: boolean) => {
          const selection = window.getSelection();

          if (hasClickTrigger && selection?.toString().length) {
            return setVisible(false);
          }

          setVisible(isVisible);
        },
        [hasClickTrigger]
      );

      const highlightElement = useCallback(() => {
        const container = getContainer();

        if (container) {
          dispatch(clearPointerSelection());

          container.classList.add(animationClass);
        }
      }, [dispatch, getContainer]);

      const {
        props: {id, className, onAnimationStart, onAnimationEnd, onMouseLeave}
      } = children;
      const isCurrentElement =
        selectionData?.elementId === id || selectionData?.relatedElement === id;

      useEffect(() => {
        if (isCurrentElement) {
          if (onSelected) {
            return onSelected(highlightElement);
          }

          highlightElement();
        }
      }, [highlightElement, isCurrentElement, onSelected]);

      useImperativeHandle(ref, () => ({
        isVisibleTooltip: () => visible,
        showTooltip: () => setVisible(true),
        hideTooltip: () => setVisible(false)
      }));

      if (passive || isNotTeacher) {
        return React.cloneElement(children, {
          className: classNames(className, 'pointer-listener', {
            'pointer-listener--inline': inline
          }),
          onAnimationStart: onAnimationStartHandler(onAnimationStart),
          onAnimationEnd: onAnimationEndHandler(onAnimationEnd)
        });
      }

      return (
        <PointerElementTooltip
          visible={visible}
          trigger={tooltipTrigger}
          align={align}
          relatedElement={relatedElement}
          getTooltipContainer={getTooltipContainer || getContainer}
          overlayClassName={overlayClassName}
          tooltipClassName={tooltipClassName}
          renderTooltipOverlay={renderTooltipOverlay}
          onVisibleChange={onVisibleChange}
          mouseLeaveDelay={mouseLeaveDelay}
          placement={placement}
        >
          {React.cloneElement(children, {
            className: classNames(className, 'pointer-listener pointer-listener--active', {
              'pointer-listener--inline': inline
            }),
            onMouseLeave: hasHoverTrigger ? onMouseLeaveHandler(onMouseLeave) : onMouseLeave,
            onAnimationStart: onAnimationStartHandler(onAnimationStart),
            onAnimationEnd: onAnimationEndHandler(onAnimationEnd)
          })}
        </PointerElementTooltip>
      );
    }
  )
);
