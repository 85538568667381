import {type Editor, Text, Transforms} from 'slate';
import {hiddenChars} from '@englex/utils';

interface Options {
  override?: true;
  stripChars?: string;
}

/**
 * TextNormalizer slate plugin
 *
 * Strips unnecessary chars from text node
 * @param stripChars
 * @param override
 */
export const withTextNormalizer =
  ({stripChars = '', override}: Options = {}) =>
  <P extends Editor>(e: P): P => {
    const invalidChars = override ? stripChars : hiddenChars().concat(stripChars);
    const testRegExp = new RegExp('^[^' + invalidChars + ']*$');
    const normalizeNode = e.normalizeNode;
    e.normalizeNode = (entry, options) => {
      const [node, path] = entry;

      if (Text.isText(node) && !testRegExp.test(node.text)) {
        for (const char of invalidChars) {
          let lastInvalidCharIndex = node.text.lastIndexOf(char);
          let positionIndex = node.text.length;
          while (lastInvalidCharIndex >= 0 && positionIndex >= 0) {
            Transforms.delete(e, {at: {path, offset: lastInvalidCharIndex}, unit: 'character'});
            positionIndex = lastInvalidCharIndex - 1;
            lastInvalidCharIndex = node.text.lastIndexOf(char, positionIndex);
          }
        }
        return;
      }

      normalizeNode(entry, options);
    };

    return e;
  };
