import React, {type FC} from 'react';
import {useSlateStatic} from 'slate-react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

import {TooltipToggle} from './TooltipToggle';
import {IconsEditor} from '../../plugins';
import {InsertIcon} from './InsertIcon';
import {Toolbar} from './Toolbar';
import {type ToolbarTooltipProps} from './Button';
import {InsertText} from './InsertText';
import {selector} from '../i18n';
import {withEditor} from '../withEditor';

const Icons: FC<ToolbarTooltipProps> = ({tooltip}) => {
  const editor = useSlateStatic();
  const icons = IconsEditor.getIconNames(editor).map(i => (
    <InsertIcon tooltip={tooltip} icon={i} key={i} />
  ));
  const chars = IconsEditor.getChars(editor).map(char => (
    <InsertText tooltip={tooltip} text={char} key={char} />
  ));
  return (
    <>
      {icons}
      {chars}
    </>
  );
};

const Overlay: FC<ToolbarTooltipProps> = ({tooltip}) => {
  return (
    <Toolbar>
      <Icons tooltip={tooltip} />
    </Toolbar>
  );
};

export const CharSelector = withEditor(
  () => {
    const intl = useIntl();
    const title = intl.formatMessage(selector.char);
    return (
      <TooltipToggle maxItemsInRow={5} Overlay={Overlay} title={title}>
        <Icon name="virc-omega" />
      </TooltipToggle>
    );
  },
  e => !!IconsEditor.getIconNames(e).length || !!IconsEditor.getChars(e).length
);
