import React, {type ElementType, type FC, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {useFocused, useSlateStatic} from 'slate-react';

import {SlateEditor} from 'components/SlateJS/plugins';

import ControlledTooltip from '../../../Slate/SlateEditor/plugins/components/ControlledTooltip';
import {Button, type ToolbarButtonProps, type ToolbarTooltipProps} from './Button';
import styles from './TooltipToggle.module.scss';

type Props = ToolbarButtonProps & {
  Overlay: ElementType<ToolbarTooltipProps>;
  maxItemsInCol?: number;
  maxItemsInRow?: number;
  vertical?: boolean;
};

const destroyTooltipOnHide = {keepParent: false};

export const TooltipToggle: FC<Props> = ({
  maxItemsInRow,
  maxItemsInCol,
  vertical,
  Overlay,
  children,
  disabled,
  ...buttonProps
}) => {
  const isFocused = useFocused();
  const className = classNames({
    [`max-in-col-${maxItemsInCol}`]: !!maxItemsInCol,
    [`max-in-row-${maxItemsInRow}`]: !!maxItemsInRow,
    vertical
  });
  const editor = useSlateStatic();
  const ref = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<ControlledTooltip | null>(null);
  const getTooltipContainer = useCallback(() => {
    const portalId = SlateEditor.toolbarPortalId(editor);
    const editorId = SlateEditor.id(editor);
    return !!portalId
      ? document.body
      : editorId
        ? document.getElementById(editorId)!
        : ref.current || document.body;
  }, [editor]);

  const tooltip = useCallback(() => {
    return tooltipRef.current;
  }, []);

  const overlay = useCallback(() => <Overlay tooltip={tooltip} />, [Overlay, tooltip]);
  const button = (
    <Button disabled={disabled} {...buttonProps}>
      {children}
    </Button>
  );

  return isFocused && !disabled ? (
    <div className={classNames(styles.toggle)} ref={ref}>
      <ControlledTooltip
        getTooltipContainer={getTooltipContainer}
        ref={tooltipRef}
        prefixCls="slate-toolbar-tooltip"
        destroyTooltipOnHide={destroyTooltipOnHide}
        overlay={overlay}
        overlayClassName={className}
      >
        <div className={classNames(styles.child)}>{button}</div>
      </ControlledTooltip>
    </div>
  ) : (
    button
  );
};
