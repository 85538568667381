export const defaultCharSet = [
  '£',
  '€',
  'ɔ',
  'ɜ',
  'ʊ',
  'ʌ',
  'ə',
  'æ',
  'ɑ',
  'ɒ',
  'ɪ',
  'ʃ',
  'ʒ',
  'ŋ',
  'ð',
  'ɵ',
  'ˌ',
  'ˈ',
  '…'
];
