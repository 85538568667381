import {useFocused, useSlateStatic} from 'slate-react';
import Tooltip from 'rc-tooltip';
import React, {type FC} from 'react';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {type PlaceholderTooltipOptions} from '../plugins';

type Props = PlaceholderTooltipOptions;

const noTrigger: TooltipProps['trigger'] = [];

export const PlaceholderTooltip: FC<Props> = ({children, placeholder, ...tooltipProps}) => {
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const overlay = placeholder?.(editor);
  const isVisible = !!overlay && isFocused;
  if (!overlay) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      visible={isVisible}
      overlay={<span>{overlay}</span>}
      getTooltipContainer={() => document.getElementById(editor.getEditorId()) as HTMLElement}
      trigger={noTrigger}
      placement="top"
      destroyTooltipOnHide={true}
      arrowContent={null}
      {...tooltipProps}
    >
      <span className="placeholder-tooltip-wrapper">{children}</span>
    </Tooltip>
  );
};
