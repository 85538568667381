import classNames from 'classnames';
import {type FC} from 'react';
import {type RenderElementProps, useFocused, useSelected, useSlateStatic} from 'slate-react';
import {Node, Text} from 'slate';

import {type FormatMarks} from '../../interface';
import {FormatEditor} from '../format';
import {IconsEditor} from './IconsEditor';

import './IconElement.scss';

interface IconProps {
  icon: string;
  additionalClass?: string;
}

type Props = RenderElementProps & IconProps;

export const IconElement: FC<Props> = ({
  attributes,
  icon: iconName,
  additionalClass,
  children,
  element
}) => {
  const isSelected = useSelected();
  const isFocused = useFocused();
  const editor = useSlateStatic();

  const icon = IconsEditor.getIcon(editor, iconName);
  const [voidText] = element.children;

  const marks = (Text.isText(voidText) ? Node.extractProps(voidText) : {}) as FormatMarks;

  const {className: markClassNames, style} = FormatEditor.formatMarkAttrs(editor, marks);

  const className = classNames('slate-icon', markClassNames, additionalClass, {
    selected: isFocused && isSelected
  });

  return (
    <span {...attributes} className={className} contentEditable={false} style={style}>
      {icon}
      {children}
    </span>
  );
};
