import {withReact, withSelectable, PaintTool, withTools} from '@englex/paint-react';
import {type PaintPlugin} from '@englex/paint';
import {withHistory} from '@englex/paint-history';

import {config} from './config';

export const plugins: PaintPlugin[] = [
  withReact(),
  withSelectable(),

  withHistory,
  withTools({active: PaintTool.Pencil, config})
];
