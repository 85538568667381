import React, {type FC} from 'react';
import {type PStage} from '@englex/paint';
import {PaintTool} from '@englex/paint-react';

import {Painter} from '../Painter/Painter';
import {plugins} from './plugins';
import {
  DebugButton,
  DownloadPNGButton,
  HistoryButton,
  Separator,
  Toolbar,
  ToolButton
} from '../Toolbar';

interface Props {
  stage: PStage;
  scale?: number;
}

export const SimplePaint: FC<Props> = ({stage, scale}) => {
  return (
    <Painter stage={stage} scale={scale} plugins={plugins}>
      <Toolbar empty={true}>
        <ToolButton tool={PaintTool.Pencil} iconName="pencil" />
        <ToolButton tool={PaintTool.Highlighter} iconName="virc-border-color" />
        <ToolButton tool={PaintTool.Arrow} iconName="arrow-popup" />
        <ToolButton tool={PaintTool.Rect} iconName="rectangle-popup" />
        <ToolButton tool={PaintTool.Text} iconName="font" />

        <Separator />

        <HistoryButton type="undo" />
        <HistoryButton type="redo" />

        <Separator />

        {import.meta.env.MODE === 'development' && (
          <>
            <DebugButton />
            <DownloadPNGButton />
          </>
        )}
      </Toolbar>
    </Painter>
  );
};
