import React, {type FC, type MouseEvent, useCallback} from 'react';
import {useFocused, useSlateSelector, useSlateStatic} from 'slate-react';
import {HistoryEditor} from 'slate-history';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

import {history} from '../i18n';
import {Button, type ToolbarButtonProps} from './Button';
import {SlateHotkey} from '../../utils/hotkeys';
import {withEditor} from '../withEditor';

interface Props extends ToolbarButtonProps {
  redo?: true;
}

const History: FC<Props> = ({redo, ...props}) => {
  const intl = useIntl();
  const editor = useSlateStatic();
  const isFocused = useFocused();

  const isUndo = !redo;

  const hasHistory = useSlateSelector(editor =>
    isUndo ? editor.history?.undos.length : editor.history?.redos.length
  );
  const isDisabled = !isFocused || !hasHistory;

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (!isDisabled) {
        isUndo ? HistoryEditor.undo(editor) : HistoryEditor.redo(editor);
      }
    },
    [editor, isDisabled, isUndo]
  );

  const shortcut = isUndo ? SlateHotkey.Undo : SlateHotkey.Redo;
  const title = isUndo ? intl.formatMessage(history.undo) : intl.formatMessage(history.redo);

  return (
    <Button
      disabled={isDisabled}
      onMouseDown={onMouseDown}
      shortcut={shortcut}
      title={title}
      {...props}
    >
      <Icon name="undo" flip={!!redo && 'horizontal'} />
    </Button>
  );
};

export const Undo: FC = withEditor(() => <History />, HistoryEditor.isHistoryEditor);
export const Redo: FC = withEditor(() => <History redo={true} />, HistoryEditor.isHistoryEditor);
