import {isAndroid, isMobileWebView} from 'helpers/browser';

import {type SlatePlugin} from '../withPlugins';

interface Options {
  enabled?: boolean;
}

const noop = (): void => {};

export const withDisabledScrollIntoView =
  ({enabled = isAndroid && !isMobileWebView()}: Options = {}): SlatePlugin =>
  e => {
    if (!enabled) return e;

    const {editableProps = {}} = e;

    editableProps.scrollSelectionIntoView = noop;

    return e;
  };
