import React, {type FC} from 'react';
import {useReadOnly} from 'slate-react';

import {type LinkElementProps} from './interface';
import {InlineChromiumBugFix} from '../../components/InlineChromiumBugFix';

import './Link.scss';

interface Props extends LinkElementProps {
  readOnly?: boolean;
}

export const Link: FC<Props> = ({attributes, className, href, target, children}) => {
  const readOnly = useReadOnly();
  return (
    <a {...attributes} href={href} target={target} className={className} rel="noopener noreferrer">
      {<InlineChromiumBugFix enabled={!readOnly} />}
      {children}
      {<InlineChromiumBugFix enabled={!readOnly} />}
    </a>
  );
};
