import {type FC} from 'react';
import {type RenderElementProps} from 'slate-react';
import './ExampleBlock.scss';

interface Props extends RenderElementProps {
  className?: string;
}

export const ExampleBlock: FC<Props> = ({
  className = 'slate-example-block',
  attributes,
  children
}) => {
  return (
    <div className={className} {...attributes}>
      {children}
    </div>
  );
};
