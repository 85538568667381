import {Editor, Transforms, Node, Text} from 'slate';

import {type NormalizeEntryFn} from '../core';
import {type LinkInline} from '../../interface';
import {LinkEditor} from './LinkEditor';

export const normalizeLink: NormalizeEntryFn<LinkInline> = (editor, [node, path]) => {
  if (!node.children.length || (!Node.string(node).length && node.children.every(Text.isText))) {
    // link has no children or text only is empty, so removing it
    Transforms.removeNodes(editor, {at: path});
    return true;
  }

  if (!node.href || !Editor.hasInlines(editor, node)) {
    // link has no href or has no texts/inlines, so unwrap it
    Transforms.unwrapNodes(editor, {at: path});
    return;
  }

  const [hasChildLink] = Editor.nodes(editor, {
    at: path,
    match: n => LinkEditor.isLink(editor, n) && n !== node
  });

  if (hasChildLink) {
    // link has child link nodes, so unwrap it to preserve parent link inline node with children string text
    Transforms.unwrapNodes(editor, {at: path, match: n => node === n});
    return;
  }

  return;
};
