import React, {type FC, useCallback, useRef} from 'react';
import {ReactEditor, useSlateStatic} from 'slate-react';

import LinkTooltip from 'components/Slate/SlateEditor/plugins/button/Link/LinkTooltip';

import {type LinkElementProps} from './interface';
import {Link} from './Link';
import {type LinkInline} from '../../interface';
import {LinkEditor} from './LinkEditor';

export const EditLink: FC<LinkElementProps> = props => {
  const {href, children, element} = props;
  const link = element as LinkInline;
  const editor = useSlateStatic();
  const ref = useRef<HTMLSpanElement | null>(null);
  const unlink = useCallback(() => {
    LinkEditor.unlink(editor);
  }, [editor]);
  const reset = useCallback(
    (focus?: true) => {
      LinkEditor.moveAfterLink(editor, link);
      focus && ReactEditor.focus(editor);
    },
    [editor, link]
  );

  const save = useCallback(
    ({href}: {href: string}) => {
      LinkEditor.update(editor, link, {href});
      LinkEditor.moveAfterLink(editor, link);
      ReactEditor.focus(editor);
    },
    [editor, link]
  );

  const getTooltipContainer = useCallback(() => ref.current!, []);
  return (
    <span onClick={e => e.stopPropagation()} ref={ref}>
      <LinkTooltip
        href={href}
        unlink={unlink}
        reset={reset}
        save={save}
        getTooltipContainer={getTooltipContainer}
        contentEditable={false}
      >
        <span>
          <Link {...props}>{children}</Link>
        </span>
      </LinkTooltip>
    </span>
  );
};
