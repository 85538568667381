import {defineMessages} from 'react-intl';

import {type MarkType} from '../definitions';

const history = defineMessages({
  undo: {
    id: 'Slate.Toolbar.Button.Undo'
  },
  redo: {
    id: 'Slate.Toolbar.Button.Redo'
  }
});

const format = defineMessages<MarkType>({
  bold: {
    id: 'Slate.Toolbar.Button.Bold.Title'
  },
  italic: {
    id: 'Slate.Toolbar.Button.Italic.Title'
  },
  underline: {
    id: 'Slate.Toolbar.Button.Underline.Title'
  },
  strikeThrough: {
    id: 'Slate.Toolbar.Button.StrikeThrough.Title'
  },
  fontSize: {
    id: 'Slate.Toolbar.Button.FontSize'
  },
  color: {
    id: 'Slate.Toolbar.Button.Color'
  },
  highlight: {
    id: 'Slate.Toolbar.Button.Highlight'
  }
});

const selector = defineMessages({
  char: {
    id: 'Slate.Toolbar.Button.CharSelector'
  }
});

const list = defineMessages({
  ol: {
    id: 'Slate.Toolbar.Button.OrderedList'
  },
  ul: {
    id: 'Slate.Toolbar.Button.UnorderedList'
  },
  decimal: {
    id: 'Slate.Toolbar.Button.OrderedListDecimal'
  },
  alpha: {
    id: 'Slate.Toolbar.Button.OrderedListAlpha'
  }
});

const inline = defineMessages({
  link: {id: 'Slate.Toolbar.Button.Link'},
  activeLink: {id: 'Slate.Toolbar.Button.Link.Active'}
});

export {format, history, list, selector, inline};
