import {Editor, Element, Node, type NodeEntry, Path, type PathRef, Range, Transforms} from 'slate';

import {type ListElement, type ListItemElement} from '../../interface';
import {type ListStyleType, type ListType, SlateBlock} from '../../../Slate/interface';
import {IconsEditor} from '../icons';
import {type ListBlockDef} from './withList';
import {SlateEditor} from '../core';

export const ListEditor = {
  is(editor: Editor): boolean {
    return (
      SlateEditor.getBlockTypes(editor).has('list') &&
      SlateEditor.getBlockTypes(editor).has('listItem')
    );
  },
  isDefined(editor: Editor, list: ListType, type?: ListStyleType): boolean {
    return (
      ListEditor.is(editor) &&
      !!SlateEditor.defRegistry(editor)?.blocks?.find(
        (b: ListBlockDef) =>
          b.block === 'list' && b.props?.list === list && b.props?.listStyleType === type
      )
    );
  },
  isList(node: Node): node is ListElement {
    return Element.isElementType<ListElement>(node, 'list');
  },
  isListType(node: Node, listType: ListType): node is ListElement {
    return ListEditor.isList(node) && node.list === listType;
  },
  isListStyleType(node: Node, listStyleType?: ListStyleType) {
    return ListEditor.isList(node) && node.listStyleType === listStyleType;
  },
  isListItem(node: Node): node is ListItemElement {
    return Element.isElementType<ListItemElement>(node, 'listItem');
  },
  isActive(editor: Editor, listType: ListType, styleType?: ListStyleType): boolean {
    const {selection} = editor;
    if (!selection || ListEditor.isDisabled(editor, listType, styleType)) return false;

    const startBlock = ListEditor.startBlock(editor);
    if (Range.isCollapsed(selection) && startBlock) {
      const entry = startBlock && ListEditor.closestListBlockOfBlock(editor, startBlock);
      if (!entry) return false;
      const [list] = entry;
      if (
        entry &&
        ListEditor.isListType(list, listType) &&
        ListEditor.isListStyleType(list, styleType)
      ) {
        // button should be active if caret is in first block of li
        const previousBlockInLi = ListEditor.previousSiblingBlockInListItem(editor, startBlock);
        return !previousBlockInLi;
      }
    }
    return false;
  },
  isDisabled(editor: Editor, listType: ListType, styleType?: ListStyleType) {
    if (!editor.selection) return true;
    const startBlock = ListEditor.startBlock(editor);

    const listBlock = startBlock && ListEditor.closestAncestor(editor, startBlock);
    const isFirstBlockInListItem =
      startBlock && !ListEditor.previousSiblingBlockInListItem(editor, startBlock);
    if (!listBlock) {
      return false;
    }
    // if startBlock is not first block in list item and has list ancestor of different type, block this button
    return !isFirstBlockInListItem && !ListEditor.isListType(listBlock[0], listType);
  },
  /**
   * Returns common list entry for all selected blocks
   */
  common(editor: Editor): NodeEntry<ListElement> | void {
    const blockEntries = ListEditor.leafBlocks(editor);
    const [first] = blockEntries;
    if (!first) return;

    const commonEntry = ListEditor.highestAbove(editor, first);
    if (!commonEntry) return;

    const [, firstPath] = commonEntry;

    const allBlocksAreInOneList = !blockEntries.find(block => {
      if (!block || ListEditor.previousSiblingBlockInListItem(editor, block)) return true;
      const parentList = ListEditor.highestAbove(editor, block);
      if (!parentList) return true;

      const [, path] = parentList;

      return !Path.equals(path, firstPath);
    });

    if (!allBlocksAreInOneList) return;

    return commonEntry;
  },
  closestAtStart(editor: Editor): NodeEntry<ListElement> | undefined {
    if (!editor.selection) return;
    const at = Editor.start(editor, editor.selection);
    return Editor.above<ListElement>(editor, {
      at,
      match: ListEditor.isList
    });
  },
  closestAncestor(editor: Editor, [, at]: NodeEntry<Element>): NodeEntry<ListElement> | null {
    for (const [element, path] of Node.ancestors(editor, at, {reverse: true})) {
      if (ListEditor.isList(element)) {
        return [element, path];
      }
    }
    return null;
  },
  closestListBlockOfBlock(
    editor: Editor,
    block: NodeEntry<Element>
  ): NodeEntry<ListElement> | undefined {
    const li = ListEditor.parentListItem(editor, block);
    if (!li) return;
    const [, at] = li;
    return Editor.parent(editor, at) as NodeEntry<ListElement>;
  },
  furthest(editor: Editor, [, path]: NodeEntry<Element>): NodeEntry<ListElement> | void {
    const index = path[0];
    if (index === undefined) return;
    const mayBeList = editor.children.at(index);
    if (!mayBeList || !ListEditor.isList(mayBeList)) {
      return;
    }
    return [mayBeList, [index]];
  },
  toggleList(editor: Editor, list: ListType, listStyleType?: ListStyleType) {
    // common list is common furthest ancestor list for all blocks, means all blocks are in one list
    const commonList = ListEditor.common(editor);

    // neighboring list is list right before the start of selection
    const neighboringList = ListEditor.beforeStart(editor, list);

    // if common list exists and there is no neighboring list or neighboring list is common list, change list type
    // or unwrap elements, depending on pressed button
    if (commonList && (!neighboringList || Path.equals(commonList[1], neighboringList[1]))) {
      ListEditor.changeTypeOrUnwrapElements(editor, list, listStyleType);
      return;
    }

    const blockEntries: [Element, PathRef][] = ListEditor.leafBlocks(editor).map(([b, p]) => [
      b,
      Editor.pathRef(editor, p)
    ]);

    blockEntries.forEach(blockEntry => {
      const [block, pathRef] = blockEntry;
      const path = pathRef.unref();
      if (!path) return;
      const entry: NodeEntry<Element> = [block, path];
      const currentList = ListEditor.closestAncestor(editor, entry);
      const prevBlockInCurrentList = ListEditor.isPreviousBlockInList(editor, entry);

      if (currentList && prevBlockInCurrentList) {
        // current block and previous block are both in the same list
        ListEditor.toggleWhenThisAndPrevBlockAreInSameList(
          editor,
          currentList,
          list,
          entry,
          listStyleType
        );
      } else {
        // if block is not in list, or previous block is not in the same list as current, move block or whole list item
        // to previous list, else unwrap it from current list and wrap in new
        ListEditor.moveToPrevListOrWrapInNew(editor, entry, list, listStyleType);
      }
    });
    const endBlock = ListEditor.endBlock(editor);

    if (!commonList && endBlock) {
      const endBlockList = ListEditor.highestAbove(editor, endBlock);
      if (!endBlockList) return;

      const nextEntry = ListEditor.nextSiblingList(editor, endBlockList);
      if (!nextEntry) return;
      const [mayBeList, at] = nextEntry;
      if (ListEditor.isListType(mayBeList, list)) {
        // after all blocks have been toggled, merge last of them to following list, if one exists
        Transforms.mergeNodes(editor, {at});
      }
    }
  },
  toggleWhenThisAndPrevBlockAreInSameList(
    editor: Editor,
    currentList: NodeEntry<ListElement>,
    listType: ListType,
    entry: NodeEntry<Element>,
    listStyleType: ListStyleType | undefined
  ) {
    const [list] = currentList;
    if (ListEditor.isListType(list, listType) && ListEditor.isListStyleType(list, listStyleType)) {
      const previousSiblingsNumber = ListEditor.previousSiblings(editor, entry).length;
      if (previousSiblingsNumber) {
        // if current block is not first block in li, split this li, making current block the first in new li.
        ListEditor.wrapTailBlockInList(editor, entry, previousSiblingsNumber);
      } else {
        // if current block is first block in li, do nothing with it.
        return;
      }
    } else {
      // button pressed is list of different type,
      // split all list items from current list after current item and change type of created list, then unwrap
      // previous tail blocks
      const [block, path] = entry;
      const pathRef = Editor.pathRef(editor, path);
      ListEditor.splitItemsAndChangeCreatedListType(
        editor,
        entry,
        currentList,
        listType,
        listStyleType
      );
      ListEditor.unwrapPreviousTailBlocks(editor, [block, pathRef.unref()!]);
    }
  },
  leafBlocks(editor: Editor): NodeEntry<Element>[] {
    const blockEntries = Editor.nodes<Element>(editor, {
      match: n => Element.isElement(n) && Editor.isBlock(editor, n) && Editor.hasInlines(editor, n),
      mode: 'lowest'
    });

    const blocks: NodeEntry<Element>[] = [];

    for (const b of blockEntries) {
      blocks.push(b);
    }
    return blocks;
  },
  highestAbove(editor: Editor, [, at]: NodeEntry<Element>): NodeEntry<ListElement> | undefined {
    return Editor.above(editor, {
      at,
      mode: 'highest',
      match: ListEditor.isList
    });
  },
  parentListItem(
    editor: Editor,
    entry: NodeEntry<Element> | null
  ): NodeEntry<ListItemElement> | undefined {
    if (!entry) return;
    const [, at] = entry;
    const parent = Editor.parent(editor, at);

    if (!parent) return;

    const [li] = parent;

    if (!ListEditor.isListItem(li)) return;

    return parent as NodeEntry<ListItemElement>;
  },
  beforeStart(editor: Editor, list: ListType): boolean | NodeEntry<ListElement> {
    const blockEntry = ListEditor.startBlock(editor);
    if (!blockEntry) {
      return false;
    }
    const [, blockPath] = blockEntry;
    if (!ListEditor.previousSiblingBlockInListItem(editor, blockEntry)) {
      const previousBlock = Editor.previous<Element>(editor, {
        at: blockPath,
        match: n => Element.isElement(n) && Editor.isBlock(editor, n)
      });

      const listBeforeSelectionEntry =
        previousBlock && ListEditor.highestAbove(editor, previousBlock);
      const previousBlockIsTailBlockInItem =
        previousBlock && ListEditor.previousSiblingBlockInListItem(editor, previousBlock);

      if (!listBeforeSelectionEntry) return false;
      const [listBeforeSelection] = listBeforeSelectionEntry;
      if (
        listBeforeSelection &&
        listBeforeSelection.list === list &&
        !previousBlockIsTailBlockInItem
      ) {
        return listBeforeSelectionEntry;
      }
    }

    return false;
  },

  isPreviousBlockInList(editor: Editor, block: NodeEntry<Element>): boolean {
    const currentList = ListEditor.highestAbove(editor, block);
    if (!currentList) return false;
    const [, at] = block;
    const prevBlock = Editor.previous<Element>(editor, {
      at,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n)
    });
    const prevBlockList = prevBlock && ListEditor.highestAbove(editor, prevBlock);
    return !!prevBlockList && Path.equals(prevBlockList[1], currentList[1]);
  },
  endBlock(editor: Editor): NodeEntry<Element> | undefined {
    const [block] = Editor.nodes<Element>(editor, {
      match: n => Element.isElement(n) && Editor.isBlock(editor, n),
      mode: 'lowest',
      reverse: true
    });
    return block;
  },
  startBlock(editor: Editor): NodeEntry<Element> | undefined {
    const [block] = Editor.nodes<Element>(editor, {
      match: n => Element.isElement(n) && Editor.isBlock(editor, n),
      mode: 'lowest'
    });
    return block;
  },
  nextSiblingList(
    editor: Editor,
    [, at]: NodeEntry<ListElement>
  ): NodeEntry<ListElement> | undefined {
    const nextPath = Path.next(at);
    if (!Editor.hasPath(editor, nextPath)) return;
    const mayBeListEntry = Editor.node(editor, nextPath);
    const [mayBeList] = mayBeListEntry;
    return ListEditor.isList(mayBeList) ? (mayBeListEntry as NodeEntry<ListElement>) : undefined;
  },
  nextListItem(editor: Editor, listItem?: NodeEntry<ListItemElement>) {
    if (!listItem) return null;
    const [, at] = listItem;
    const nextLi = Editor.next<ListItemElement>(editor, {
      at,
      match: n => ListEditor.isListItem(n)
    });
    return nextLi || null;
  },
  changeType(
    editor: Editor,
    [targetList, targetPath]: NodeEntry<ListElement>,
    list: ListType,
    listStyleType: ListStyleType | undefined
  ) {
    Transforms.setNodes(
      editor,
      {list, listStyleType: listStyleType || (null as never)},
      {
        at: [],
        mode: 'all',
        match: (n, p) => {
          const result =
            n === targetList || (Path.isDescendant(p, targetPath) && ListEditor.isList(n));
          return result;
        }
      }
    );
  },
  changeTypeOrUnwrapElements(editor: Editor, list: ListType, listStyleType?: ListStyleType) {
    const startBlock = ListEditor.startBlock(editor);
    if (!startBlock) return;
    const closestAncestor = ListEditor.closestAncestor(editor, startBlock);
    if (!closestAncestor) return;
    // every block is in one list, so startBlock is definitely in list
    const [ancestor] = closestAncestor;
    if (
      !ListEditor.isListType(ancestor, list) ||
      !ListEditor.isListStyleType(ancestor, listStyleType)
    ) {
      ListEditor.changeType(editor, closestAncestor, list, listStyleType);
    } else {
      const blocks = ListEditor.leafBlocks(editor);
      blocks.reverse().forEach(block => {
        // we are just moving blocks from list item, so targetList shouldn't change
        ListEditor.moveBlockFromListItem(editor, block);
      });
    }
  },
  changeTypeForBlock(
    editor: Editor,
    block: NodeEntry<Element>,
    list: ListType,
    listStyleType?: ListStyleType
  ) {
    const listBlock = ListEditor.highestAbove(editor, block);
    if (!listBlock) return;
    ListEditor.changeType(editor, listBlock, list, listStyleType);
  },
  previousListItem(
    editor: Editor,
    listItem?: NodeEntry<ListItemElement>
  ): NodeEntry<ListItemElement> | null {
    if (!listItem) return null;
    const [, at] = listItem;
    const li = Editor.previous<ListItemElement>(editor, {
      at,
      match: (n, p) => ListEditor.isListItem(n) && Path.isSibling(p, at)
    });
    return li || null;
  },
  previousSiblingBlockInListItem(
    editor: Editor,
    entry: NodeEntry<Element>
  ): NodeEntry<Element> | undefined {
    const [, at] = entry;
    if (!ListEditor.parentListItem(editor, entry)) {
      return;
    }
    return Editor.previous<Element>(editor, {at});
  },
  previousSiblings(editor: Editor, entry: NodeEntry<Element>): NodeEntry<Element>[] {
    const [, path] = entry;
    const [parent, parentPath] = Editor.parent(editor, path);
    const siblings: NodeEntry<Element>[] = [];
    parent.children.forEach((element: Element, i) => {
      const p = [...parentPath, i];
      if (Path.isBefore(p, path)) {
        siblings.push([element, p]);
      }
    });
    return siblings;
  },
  moveBlockFromListItem(editor: Editor, entry: NodeEntry<Element>) {
    // we know for sure every block is in list item because otherwise we wouldn't be unwrapping blocks
    const [block, path] = entry;
    const listItem = ListEditor.parentListItem(editor, entry);
    const previousLi = ListEditor.previousListItem(editor, listItem);
    const nextLi = ListEditor.nextListItem(editor, listItem);
    const embeddedListIndex = listItem ? listItem[0].children.findIndex(ListEditor.isList) : -1;
    const isMiddleLi = previousLi && nextLi;
    const isLastLiWithEmbeddedList = previousLi && embeddedListIndex >= 0;
    if (isMiddleLi || isLastLiWithEmbeddedList) {
      // current li is in the middle of list, meaning it has previous li and next li, or is last li of list and has
      // embedded list in it; then move current block and all it's next siblings to previous li
      ListEditor.moveBlockAndNextSiblingsToPrevLi(editor, entry, previousLi);
      return;
    }

    const embeddedListEntry: NodeEntry<ListElement> | null =
      listItem && embeddedListIndex >= 0
        ? [
            listItem[0].children[embeddedListIndex] as ListElement,
            [...listItem[1], embeddedListIndex]
          ]
        : null;

    if (!previousLi && listItem) {
      // current li is first in list; if it contains embedded list, move all blocks from this list to destination list,
      // then unwrap what's left in current li from list

      if (embeddedListEntry) {
        // every li is always inside of list, so assert as ListBlock here
        const parentListEntry = Editor.parent(editor, listItem[1]) as NodeEntry<ListElement>;
        ListEditor.moveItemsFromEmbeddedListToParentList(
          editor,
          embeddedListEntry,
          parentListEntry
        );
      }
      ListEditor.unwrapFromList(editor, listItem);
      return;
    }

    if (!nextLi) {
      // current li is last in list and has no embedded list; first, move current block recursively from lists on all levels
      // where it is in last li of list on this level
      const pathRef = Editor.pathRef(editor, path);

      ListEditor.moveFromListsOnAllLevelsWhereIsInLastLi(editor, entry);

      if (pathRef.current && !ListEditor.closestAncestor(editor, [block, pathRef.current])) {
        // if block was in last li on all list indentation levels, now it is child of document; go through all blocks before it
        // while we don't come across first block in li and move these tail blocks to document as well;
        ListEditor.unwrapPreviousTailBlocks(editor, [block, pathRef.current]);
      }
      pathRef.unref();

      return;
    }

    return;
  },
  moveBlockAndNextSiblingsToPrevLi(
    editor: Editor,
    startBlock: NodeEntry<Element>,
    targetListItem: NodeEntry<ListItemElement>
  ) {
    const [, startPath] = startBlock;
    // we know for sure block is in list item, otherwise it shouldn't have been unwrapped
    const liEntry = ListEditor.parentListItem(editor, startBlock);
    if (!liEntry) return;
    const [li, liPath] = liEntry;
    // const blockAndNextSiblings = listItem.nodes.skipUntil((b: Block) => b.key === startBlock.key);
    const blockAndNextSiblings: NodeEntry<Element>[] = [];
    li.children.forEach((n, i) => {
      const path = [...liPath, i];
      if (Path.equals(path, startPath) || Path.isAfter(path, startPath)) {
        blockAndNextSiblings.push([n, path]);
      }
    });
    // iterate over blocks in reverse order (reverse here is for optimization: do not get nodes count on each step in forEach below)
    Editor.withoutNormalizing(editor, () => {
      blockAndNextSiblings.reverse().forEach(n => {
        // targetListItem stays the same here because we just move some blocks in it, and normalizer shouldn't do anything
        ListEditor.moveBlockToEndOfListItem(editor, n, targetListItem);
      });
    });
  },
  moveBlockToEndOfListItem(
    editor: Editor,
    [, at]: NodeEntry<Element>,
    [li, path]: NodeEntry<ListItemElement>
  ) {
    Transforms.moveNodes(editor, {at, to: [...path, li.children.length]});
  },
  moveToPrevListOrWrapInNew(
    editor: Editor,
    blockEntry: NodeEntry<Element>,
    list: ListType,
    listStyleType?: ListStyleType
  ) {
    const [block, path] = blockEntry;
    const prevBlock = Editor.previous<Element>(editor, {
      at: path,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n)
    });
    if (prevBlock) {
      const prevListEntry = ListEditor.highestAbove(editor, prevBlock);
      const [prevList, prevListPath] = prevListEntry || [];
      if (
        prevList &&
        prevListPath &&
        ListEditor.isListType(prevList, list) &&
        ListEditor.isListStyleType(prevList, listStyleType)
      ) {
        const listItem = ListEditor.parentListItem(editor, blockEntry);
        // if current block is in list item, move whole list item, else move just this block
        const [, movePath] = listItem || blockEntry;
        Transforms.moveNodes(editor, {
          at: movePath,
          to: [...prevListPath, prevList.children.length]
        });

        return;
      }
    }

    const node: ListElement = {type: 'list', list, children: []};
    if (listStyleType) {
      node.listStyleType = listStyleType;
    }

    const pathRef = Editor.pathRef(editor, path);
    Transforms.liftNodes(editor, {
      match: (n: Element) => ListEditor.isListItem(n) && n.children.includes(block)
    });
    Transforms.wrapNodes(editor, node, {at: pathRef.unref()!});
  },
  moveFromListsOnAllLevelsWhereIsInLastLi(editor: Editor, entry: NodeEntry<Element>) {
    const listItem = ListEditor.parentListItem(editor, entry);
    // check if block is in listItem and this listItem is last in list
    if (!listItem || ListEditor.nextListItem(editor, listItem)) {
      return;
    }

    // current block is in list item, which means it has list and this list has its own parent block
    const closestListEntry = ListEditor.closestAncestor(editor, entry);

    if (!closestListEntry) return;
    const [closestList, closestListPath] = closestListEntry;
    const [destBlock, destPath] = Editor.parent(editor, closestListPath);
    if (!destBlock) return;

    const [, at] = listItem;
    const [block, blockPath] = entry;
    const index = (destBlock.children as Element[]).indexOf(closestList);
    // move block from list and check the same on next level
    Editor.withoutNormalizing(editor, () => {
      const to = [...destPath, index + 1];
      Transforms.moveNodes(editor, {at: blockPath, to});
      ListEditor.moveFromListsOnAllLevelsWhereIsInLastLi(editor, [block, to]);
      const [[n]] = Editor.nodes<Element>(editor, {
        at,
        match: ListEditor.isListItem
      });
      const listItemHasNoBlocksLeft = n && !Editor.hasBlocks(editor, n);
      if (listItemHasNoBlocksLeft) {
        Transforms.removeNodes(editor, {at});
      }
    });
  },
  moveParagraphAfterEmbeddedListAsLastBlockOfEmbeddedList(
    editor: Editor,
    [, at]: NodeEntry<Element>
  ) {
    // if paragraph or image goes in li after embedded list, move it to the last block of this embedded list
    // const prevBlock = change.value.document.getPreviousBlock(child.key);
    const prevEntry = Editor.previous<Element>(editor, {
      at,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n)
    });
    if (prevEntry) {
      const targetLiEntry = ListEditor.parentListItem(editor, prevEntry);
      if (targetLiEntry) {
        const [targetLi, targetLiPath] = targetLiEntry;
        // change.moveNodeByKey(child.key, targetLi.key, targetLi.nodes.size);
        const to = [...targetLiPath, targetLi.children.length];
        Transforms.moveNodes(editor, {at, to});
      }
    }
    return;
  },
  moveItemsFromEmbeddedListToParentList(
    editor: Editor,
    [embeddedList, embeddedListPath]: NodeEntry<ListElement>,
    [, parentListPath]: NodeEntry<ListElement>
  ) {
    // parentList stays the same here because we just move some list items from embedded blocks to it, and normalizer shouldn't do anything
    const pathRefs = embeddedList.children.map((n, i) =>
      Editor.pathRef(editor, [...embeddedListPath, i])
    );
    pathRefs.forEach((pathRef, index) => {
      const at = pathRef.unref()!;
      const to = Path.next([...parentListPath, index]);
      Transforms.moveNodes(editor, {at, to});
    });
  },
  wrapTailBlockInList(editor: Editor, block: NodeEntry<Element>, previousSiblingsNumber: number) {
    const liEntry = ListEditor.parentListItem(editor, block);
    if (!liEntry) return;
    const [, liPath] = liEntry;
    const path = [...liPath, previousSiblingsNumber];
    Transforms.splitNodes(editor, {at: path});
  },
  unwrapFromList(editor: Editor, [, at]: NodeEntry<ListItemElement>) {
    Transforms.liftNodes(editor, {at});
  },
  unwrapPreviousTailBlocks(editor: Editor, [, at]: NodeEntry<Element>) {
    // if there is nextLi, which means unwrapped item was last in the list, recursively get previous block and unwrap them
    // while previous block is not first block in list item
    const prevEntry = Editor.previous<Element>(editor, {
      at,
      mode: 'lowest',
      match: n => Element.isElement(n) && Editor.hasInlines(editor, n)
    });
    if (!prevEntry || !ListEditor.previousSiblingBlockInListItem(editor, prevEntry)) return;

    const [prev, prevPath] = prevEntry;
    // we are sure previous block is in list because it is in list item as previous check stated
    const prevListEntry = ListEditor.highestAbove(editor, prevEntry);
    if (!prevListEntry) return;
    const [prevList] = prevListEntry;
    const index = editor.children.indexOf(prevList);
    const to = [index + 1];

    Transforms.moveNodes(editor, {at: prevPath, to});
    ListEditor.unwrapPreviousTailBlocks(editor, [prev, to]);
  },
  unwrapNextBlock(editor: Editor): boolean | void {
    // if next block is in list, and current block is not in the same list, we should unwrap next block
    const startBlockEntry = ListEditor.startBlock(editor);
    if (!startBlockEntry) return;
    const [, startBlockPath] = startBlockEntry;
    const nextBlockEntry = Editor.next<Element>(editor, {
      at: startBlockPath,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n) && Editor.hasInlines(editor, n)
    });
    if (!nextBlockEntry) return;
    const [, nextBlockPath] = nextBlockEntry;
    if (ListEditor.shouldUnwrapNextBlock(editor)) {
      const ref = Editor.pathRef(editor, nextBlockPath);
      ListEditor.moveBlockFromListItem(editor, nextBlockEntry);
      const at = ref.unref()!;
      if (!!ListEditor.parentListItem(editor, startBlockEntry)) return;
      // fixes issue of reverting this operation when start block is not in list
      at && Transforms.mergeNodes(editor, {at});
      return true;
    }
  },
  splitItemsAndChangeCreatedListType(
    editor: Editor,
    entry: NodeEntry<Element>,
    listBlock: NodeEntry<ListElement>,
    list: ListType,
    listStyleType?: ListStyleType
  ) {
    const listItem = ListEditor.parentListItem(editor, entry);
    if (!listItem) return;
    const indexOfListItem = ListEditor.previousSiblings(editor, listItem).length;

    const [block, blockPath] = entry;
    const [, listPath] = listBlock;
    const path = [...listPath, indexOfListItem];

    const pathRef = Editor.pathRef(editor, blockPath);
    Transforms.splitNodes(editor, {at: path});
    ListEditor.changeTypeForBlock(editor, [block, pathRef.unref()!], list, listStyleType);
  },
  shouldUnwrapNextBlock(editor: Editor): boolean | void {
    const {selection} = editor;
    const startBlockEntry = ListEditor.startBlock(editor);
    if (!selection || Range.isExpanded(selection) || !startBlockEntry) return;
    const [startBlock, startBlockPath] = startBlockEntry;

    const nextBlockEntry = Editor.next<Element>(editor, {
      at: startBlockPath,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n) && Editor.hasInlines(editor, n)
    });
    if (!nextBlockEntry) return;
    const nextBlockList = ListEditor.furthest(editor, nextBlockEntry);

    const thisBlockList = ListEditor.furthest(editor, startBlockEntry);

    if (!nextBlockList) return;
    const thisBlockNotInList = !thisBlockList;
    const nextBlockInAnotherList =
      thisBlockList && !Path.equals(nextBlockList[1], thisBlockList[1]);
    const selectionAtEndOfBlock = Editor.isEnd(editor, Range.start(selection), startBlockPath);
    const blockIsParagraph = !startBlock.type || startBlock.type === SlateBlock.DEFAULT;

    return (
      (nextBlockInAnotherList || thisBlockNotInList) && selectionAtEndOfBlock && blockIsParagraph
    );
  },
  deleteWithAfterAction(editor: Editor): boolean | void {
    // TODO: strange method, rethink it necessity
    if (!editor.selection) return;
    const startBlock = ListEditor.startBlock(editor);
    const endBlock = ListEditor.endBlock(editor);
    const currentLiEntry = startBlock && ListEditor.parentListItem(editor, startBlock);
    if (!currentLiEntry || !endBlock) return;
    const [start, end] = Editor.edges(editor, editor.selection);
    const [, currentLiPath] = currentLiEntry;
    const isAtStart =
      Editor.isStart(editor, start, currentLiPath) && Range.isForward(editor.selection);
    const isAtEnd = Editor.isEnd(editor, end, currentLiPath) && Range.isForward(editor.selection);

    const listBlockOfEndBlock = ListEditor.furthest(editor, endBlock);
    const [list] = ListEditor.closestAtStart(editor) || [];
    if (list && !listBlockOfEndBlock && (isAtStart || isAtEnd)) {
      // if anchor or focus is at start of the first list item in selection, and last block in selection is not in list,
      // delete selected content and then unwrap what's left
      Editor.deleteFragment(editor);
      ListEditor.toggleList(editor, list.list, list.listStyleType);
      return true;
    }

    const listBlockOfStartBlock = ListEditor.furthest(editor, startBlock)!;
    if (
      list &&
      listBlockOfEndBlock &&
      !Path.equals(listBlockOfStartBlock[1], listBlockOfEndBlock[1])
    ) {
      // if selection starts in one list and finishes in another, merge two lists after delete
      Editor.deleteFragment(editor);
      const endBlockAfterDelete = ListEditor.endBlock(editor);
      if (!endBlockAfterDelete) {
        return true;
      }
      const endBlockList = ListEditor.furthest(editor, endBlockAfterDelete);
      if (!endBlockList) return;
      const followingList = ListEditor.nextSiblingList(editor, endBlockList);
      if (followingList && ListEditor.isListType(followingList[0], list.list)) {
        // after all blocks have been toggled, merge last of them to following list, if one exists
        // change.mergeNodeByKey(followingList.key);
        Transforms.mergeNodes(editor, {at: followingList[1]});
      }

      return true;
    }

    return false;
  },
  enter(editor: Editor): boolean | void {
    const listEntry = ListEditor.closestAtStart(editor);

    if (!editor.selection || !listEntry) return; // TODO: true???

    const [list] = listEntry;
    const listType: ListType = list.list;
    const listStyleType: ListStyleType | undefined = list.listStyleType;

    const [startBlockEntry] = Editor.nodes<Element>(editor, {
      match: n => Element.isElement(n) && Editor.hasInlines(editor, n)
    });

    if (!startBlockEntry) return;

    const [startBlock] = startBlockEntry;

    const blockHasVoidInline = !!(startBlock.children as Element[]).find(
      (node: Element) => Editor.isInline(editor, node) && Editor.isVoid(editor, node)
    );
    const [startInline] = Editor.nodes<Element>(editor, {
      match: n => Element.isElement(n) && Editor.isInline(editor, n) && Editor.isVoid(editor, n)
    });
    // if cursor is in one of icons in this block, do nothing
    if (blockHasVoidInline && startInline && IconsEditor.isIconOnlySelected(editor)) {
      return;
    }

    // TODO: something from indent/outdent should be implemented
    // // if outdent plugin is present in this editor,
    // // first check if should outdent embedded list item on enter, and if so, do nothing in this handler
    // const outdentPlugin = editor
    //   .query<ToolbarButton[]>(getToolbarButtons)
    //   .find((plugin: ToolbarButton) => plugin.type === ButtonType.OUTDENT) as Outdent | undefined;
    // if (outdentPlugin && outdentPlugin.handleKeyDown!(event, editor)) {
    //   return;
    // }

    const previousSiblingBlock = ListEditor.previousSiblingBlockInListItem(editor, startBlockEntry);

    // even if text is empty, we still have to check for icons to consider block empty
    const blockIsEmpty = !Node.string(startBlock).length && !blockHasVoidInline;

    // TODO: this.shouldUnwrapEmptyLiOnEnter ???
    if (!previousSiblingBlock && blockIsEmpty /* && this.shouldUnwrapEmptyLiOnEnter*/) {
      // if block is empty and is first block in li, call toggleList, which will unwrap current li
      ListEditor.toggleList(editor, listType, listStyleType);
    } else {
      // if block has no previous sibling block in li, we should make the new li, so split with depth 2
      // if block has previous sibling block, we should make the new p in li, so just split p with depth 1
      // const splitDepth = !previousSiblingBlock ? 2 : 1;
      if (!previousSiblingBlock) {
        Transforms.splitNodes(editor, {match: n => ListEditor.isListItem(n), always: true});
      } else {
        editor.insertSoftBreak();
      }

      // change.splitBlock(splitDepth);
    }
    return true;
  },
  softEnter(editor: Editor) {
    editor.insertText('\n');
    return true;
  },
  delete(editor: Editor): boolean | void {
    const startBlockEntry = ListEditor.startBlock(editor);

    if (!startBlockEntry) return;
    const [startBlock, startBlockPath] = startBlockEntry;
    const [list] = ListEditor.closestAtStart(editor) || [];
    if (!list) {
      return ListEditor.unwrapNextBlock(editor);
    }
    const blockHasVoidInlines = !!(startBlock.children as Element[]).find(
      (node: Element) => Editor.isInline(editor, node) && Editor.isVoid(editor, node)
    );

    const blockIsEmpty = !Node.string(startBlock).length && !blockHasVoidInlines;

    if (blockIsEmpty) {
      const isFirstBlockInLi = !ListEditor.previousSiblingBlockInListItem(editor, startBlockEntry);

      if (isFirstBlockInLi) {
        // if delete pressed in first block of li, which is empty, unwrap this block
        ListEditor.toggleList(editor, list.list, list.listStyleType);
      } else {
        // if delete pressed in tail block of li, which is empty, delete this block and then move cursor to the start of next block
        Transforms.removeNodes(editor, {at: startBlockPath});
        Transforms.move(editor, {edge: 'focus'});
        Transforms.move(editor, {edge: 'anchor'});
      }
      return true;
    }

    const endBlockEntry = ListEditor.endBlock(editor);

    if (endBlockEntry && ListEditor.deleteWithAfterAction(editor)) {
      return true;
    }

    return ListEditor.unwrapNextBlock(editor);
  },
  backspace(editor: Editor): boolean | void {
    if (!editor.selection) return;
    const startBlock = ListEditor.startBlock(editor);
    const currentLiEntry = startBlock && ListEditor.parentListItem(editor, startBlock);
    if (!currentLiEntry) return;
    const [, currentLiPath] = currentLiEntry;
    const [start] = Editor.edges(editor, editor.selection);
    const isAtStartOfLi =
      Range.isCollapsed(editor.selection) && Editor.isStart(editor, start, currentLiPath);
    if (isAtStartOfLi) {
      // if cursor is at start of item, unwrap this item
      ListEditor.moveBlockFromListItem(editor, startBlock);
      return true;
    }
    //
    if (ListEditor.deleteWithAfterAction(editor)) {
      return true;
    }
    return;
  },
  getFurthestSubsequentListOfType(
    editor: Editor,
    entry: NodeEntry<ListElement>,
    listType: ListType,
    listStyleType?: ListStyleType
  ) {
    const furthestList = ListEditor.getSubsequentListOfType(editor, entry, listType, listStyleType);
    return !Path.equals(entry[1], furthestList[1]) ? furthestList : undefined;
  },
  getSubsequentListOfType(
    editor: Editor,
    list: NodeEntry<ListElement>,
    listType: ListType,
    listStyleType?: ListStyleType
  ): NodeEntry<ListElement> {
    const parentLi = ListEditor.parentListItem(editor, list);
    const parentList = parentLi && (Editor.parent(editor, parentLi[1]) as NodeEntry<ListElement>);

    return parentList &&
      ListEditor.isList(parentList[0]) &&
      ListEditor.isListType(parentList[0], listType) &&
      ListEditor.isListStyleType(parentList[0], listStyleType)
      ? ListEditor.getSubsequentListOfType(editor, parentList, listType, listStyleType)
      : list;
  }
};
