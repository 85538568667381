import React, {type FC} from 'react';
import {Transforms} from 'slate';
import {useFocused, useSlateStatic} from 'slate-react';

import {Button, type ToolbarTooltipProps} from './Button';

type Props = ToolbarTooltipProps & {
  className?: string;
  text: string;
};

export const InsertText: FC<Props> = ({className, children, text, tooltip}) => {
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const isDisabled = !isFocused;

  const onClick = (e: React.MouseEvent) => {
    if (isDisabled) return;
    e.preventDefault();
    e.stopPropagation();
    tooltip?.()?.hide();
    Transforms.insertText(editor, text);
  };

  return (
    <Button onClick={onClick} className={className}>
      {children || text}
    </Button>
  );
};
