import {type FC} from 'react';
import classNames from 'classnames';
import {useFocused, useReadOnly} from 'slate-react';

import styles from './Toolbar.module.scss';

interface Props {
  inPortal?: boolean;
}

export const Toolbar: FC<Props> = ({children, inPortal}) => {
  const isReadOnly = useReadOnly();
  const isFocused = useFocused();
  const hide = isReadOnly || (inPortal && !isFocused);

  if (hide) return null;

  return (
    <div className={classNames('slate-editor-toolbar', styles.toolbar, {'in-portal': !!inPortal})}>
      {children}
    </div>
  );
};
