import React, {
  type ButtonHTMLAttributes,
  forwardRef,
  type MouseEvent,
  type PropsWithChildren
} from 'react';
import classNames from 'classnames';
import {useFocused} from 'slate-react';

import {buttonTitle} from 'helpers/title';
import {type Shortcut} from 'helpers/shortcut';
import type ControlledTooltip from 'components/Slate/SlateEditor/plugins/components/ControlledTooltip';
import Icon from 'components/Icon';

import styles from './Button.module.scss';

export interface ToolbarTooltipProps {
  tooltip?: () => ControlledTooltip | null;
}

export interface ToolbarButtonProps
  extends ToolbarTooltipProps,
    Omit<
      React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      'ref' | 'value' | 'type' | 'title'
    > {
  icon?: string | [string, string];
  shortcut?: Shortcut;
  active?: boolean;
  title?: string | null;
}

const preventEditorLooseFocus = (e: MouseEvent) => {
  e.preventDefault();
};

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ToolbarButtonProps>>(
  (
    {
      children,
      className,
      style,
      disabled: isDisabled,
      shortcut,
      title,
      active: isActive,
      onMouseDown = preventEditorLooseFocus,
      tooltip: _,
      icon: iconProp,
      ...rest
    },
    ref
  ) => {
    const isFocused = useFocused();
    const active = isFocused && !!isActive;
    const disabled = !!isDisabled || !isFocused;
    const btnTitle = buttonTitle(title, shortcut);
    const tabIndex = isDisabled ? -1 : undefined;

    const iconName = Array.isArray(iconProp) ? iconProp : [iconProp, iconProp];

    const [activeIcon, inactiveIcon] = iconProp ? iconName : [];
    const icon = isActive ? activeIcon : inactiveIcon;

    return (
      <button
        tabIndex={tabIndex}
        {...rest}
        type="button"
        onMouseDown={onMouseDown}
        title={btnTitle}
        className={classNames('stb', styles.button, className, {
          [styles.active]: active,
          [styles.disabled]: disabled,
          active,
          disabled
        })}
        style={style}
        ref={ref}
      >
        {icon ? <Icon name={icon} /> : children}
      </button>
    );
  }
);
